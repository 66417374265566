import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Fieldset} from 'primereact/fieldset';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {fetch_url_post,add_to_array,remove_from_array,clone_object} from "../../Sources/js/app.js";

class Familie extends React.Component {
	constructor(props) {
		super(props);
		this.reset_data={
			nume_mama: "",
			cnp_mama: "",
			adresa_mama: "",
			telefon_mama: "",
			nume_tata: "",
			cnp_tata: "",
			adresa_tata: "",
			telefon_tata: "",
			localitate_mama: "",
			localitate_tata: "",
		}
		this.reset_data_frati={
			nume_frate: "",
			cnp_frate: "",
			id_copil: this.props.copil.id,
		}
		this.reset_data_rude={
			nume_ruda: "",
			cnp_ruda: "",
			grad_rudenie: "",
			adresa_ruda: "",
			telefon_ruda: "",
			localitate_ruda: "",
			id_copil: this.props.copil.id,
		}
		this.state={
            is_loaded: 0,
			lista_frati: [],
			lista_rude: [],
			message_open: false,
			message_text: "",
			data:clone_object(this.reset_data),
			data_frati:clone_object(this.reset_data_frati),
			data_rude:clone_object(this.reset_data_rude),
			grade_rudenie_list: [
				{
					label: "III",
					value: 3,
				},
				{
					label: "IV",
					value: 4,
				},				
			],
        };
        this.oper="";
		this.modif_el={};
		this.url="familie.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.date_parinti=this.date_parinti.bind(this);
		this.date_frati=this.date_frati.bind(this);
		this.date_rude=this.date_rude.bind(this);
		this.salveaza_date_parinti=this.salveaza_date_parinti.bind(this);
		this.salveaza_date_frati=this.salveaza_date_frati.bind(this);
		this.salveaza_date_rude=this.salveaza_date_rude.bind(this);
	}
		
	success_handler(result) {
		if(this.oper==="lista") {
			this.oper="";
			if(result.ok===1) {
				let tmp=this.state.data;
				tmp.nume_mama=result.data.nume_mama;
				tmp.cnp_mama=result.data.cnp_mama;
				tmp.adresa_mama=result.data.adresa_mama;
				tmp.localitate_mama=result.data.localitate_mama;
				tmp.telefon_mama=result.data.telefon_mama;
				tmp.nume_tata=result.data.nume_tata;
				tmp.cnp_tata=result.data.cnp_tata;
				tmp.adresa_tata=result.data.adresa_tata;
				tmp.localitate_tata=result.data.localitate_tata;
				tmp.telefon_tata=result.data.telefon_tata;
				this.setState({
					is_loaded: 1,
					data: tmp,
					lista_frati: result.data.lista_frati,
					lista_rude: result.data.lista_rude,					
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="salveaza_date_parinti") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					message_open: true,
					message_text: "Datele au fost salvate cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="adauga_date_frati") {
			this.oper="";
			if(result.ok===1) {
				let new_item=this.state.data_frati;
				new_item.id=result.new_id;
				this.setState({
					lista_frati: add_to_array(this.state.lista_frati,new_item),
					data_frati: clone_object(this.reset_data_frati),
					message_open: true,
					message_text: "Datele au fost adaugate cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="modifica_date_frati") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					message_open: true,
					message_text: "Datele au fost modificate cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="sterge_date_frati") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					lista_frati: remove_from_array(this.state.lista_frati,result.data),
					message_open: true,
					message_text: "Datele au fost sterse cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="adauga_date_rude") {
			this.oper="";
			if(result.ok===1) {
				let new_item=this.state.data_rude;
				new_item.id=result.new_id;
				this.setState({
					lista_rude: add_to_array(this.state.lista_rude,new_item),
					data_rude: clone_object(this.reset_data_rude),
					message_open: true,
					message_text: "Datele au fost adaugate cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="modifica_date_rude") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					message_open: true,
					message_text: "Datele au fost modificate cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="sterge_date_rude") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					lista_rude: remove_from_array(this.state.lista_rude,result.data),
					message_open: true,
					message_text: "Datele au fost sterse cu succes!",
				});
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});				
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="lista";
		var data={
			oper: this.oper,
			id: this.props.copil.id,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	updateInputValue(evt,tip,index) {
		let tmp;
		if(tip==="data") {
			tmp=this.state.data;
			tmp[evt.target.name]=evt.target.value;
			this.setState({
				data: tmp,
			});
		}
		if(tip==="data_frati") {
			tmp=this.state.data_frati;
			tmp[evt.target.name]=evt.target.value;
			this.setState({
				data_frati: tmp,
			});
		}
		if(tip==="data_frati_lista") {
			tmp=this.state.lista_frati;
			tmp[index][evt.target.name]=evt.target.value;
			this.setState({
				lista_frati: tmp,
			});
		}
		if(tip==="data_rude") {
			tmp=this.state.data_rude;
			tmp[evt.target.name]=evt.target.value;
			this.setState({
				data_rude: tmp,
			});
		}
		if(tip==="data_rude_lista") {
			tmp=this.state.lista_rude;
			tmp[index][evt.target.name]=evt.target.value;
			this.setState({
				lista_rude: tmp,
			});
		}
	}

	salveaza_date_parinti() {
		if((this.state.data.nume_mama==="")||(this.state.nume_tata==="")) {
			this.setState({
				message_open: true,
				message_text: "Completati campurile marcate cu *!",
			});	
			return false;			
		}
		this.oper="salveaza_date_parinti";
		var data={
			oper: this.oper,
			id: this.props.copil.id,
			data: this.state.data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);				
	}

	salveaza_date_frati(evt,tip,index) {
		var data;
		if(tip==="adaugare") {
			if(this.state.data_frati.nume_frate==="") {
				this.setState({
					message_open: true,
					message_text: "Completati campurile marcate cu *!",
				});	
				return false;			
			}
			this.oper="adauga_date_frati";
			data={
				oper: this.oper,
				data: this.state.data_frati,
			};
		}
		if(tip==="modificare") {
			this.oper="modifica_date_frati";
			data={
				oper: this.oper,
				data: this.state.lista_frati[index],
			};
		}
		if(tip==="stergere") {
			if(window.confirm("Sigur doriti stergerea?")) {
				this.oper="sterge_date_frati";
				data={
					oper: this.oper,
					data: this.state.lista_frati[index],
				};
			}
		}
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);				
	}

	salveaza_date_rude(evt,tip,index) {
		var data;
		if(tip==="adaugare") {
			if((this.state.data_rude.nume_frate==="")||(this.state.data_rude.grad_rudenie==="")) {
				this.setState({
					message_open: true,
					message_text: "Completati campurile marcate cu *!",
				});	
				return false;			
			}
			this.oper="adauga_date_rude";
			data={
				oper: this.oper,
				data: this.state.data_rude,
			};
		}
		if(tip==="modificare") {
			this.oper="modifica_date_rude";
			data={
				oper: this.oper,
				data: this.state.lista_rude[index],
			};
		}
		if(tip==="stergere") {
			if(window.confirm("Sigur doriti stergerea?")) {
				this.oper="sterge_date_rude";
				data={
					oper: this.oper,
					data: this.state.lista_rude[index],
				};
			}
		}
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);				
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	date_parinti() {
		return(
			<Grid container spacing={1}>
				<Grid item xs={3} style={{"textAlign":"center"}}>
					<TextField
						required
						variant="outlined"
						margin="dense"
						name="nume_mama"
						id="nume_mama"
						label="Nume mama"
						fullWidth
						autoComplete="nume_mama"
						value={this.state.data.nume_mama}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={3} style={{"textAlign":"center"}}>
					<TextField
						variant="outlined"
						margin="dense"
						name="cnp_mama"
						id="cnp_mama"
						label="CNP mama"
						fullWidth
						autoComplete="cnp_mama"
						value={this.state.data.cnp_mama}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={3} style={{"textAlign":"center"}}>
					<TextField
						required
						variant="outlined"
						margin="dense"
						name="nume_tata"
						id="nume_tata"
						label="Nume tata"
						fullWidth
						autoComplete="nume_tata"
						value={this.state.data.nume_tata}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={3} style={{"textAlign":"center"}}>
					<TextField
						variant="outlined"
						margin="dense"
						name="cnp_tata"
						id="cnp_tata"
						label="CNP tata"
						fullWidth
						autoComplete="cnp_tata"
						value={this.state.data.cnp_tata}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={6} style={{"textAlign":"center"}}>
					<TextField
						variant="outlined"
						margin="dense"
						name="adresa_mama"
						id="adresa_mama"
						label="Adresa mama"
						fullWidth
						autoComplete="adresa_mama"
						value={this.state.data.adresa_mama}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={3} style={{"textAlign":"center"}}>
					<TextField
						required
						variant="outlined"
						margin="dense"
						name="localitate_mama"
						id="localitate_mama"
						label="Localitate"
						fullWidth
						autoComplete="localitate_mama"
						value={this.state.data.localitate_mama}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={3} style={{"textAlign":"center"}}>
					<TextField
						variant="outlined"
						margin="dense"
						name="telefon_mama"
						id="telefon_mama"
						label="Telefon mama"
						fullWidth
						autoComplete="telefon_mama"
						value={this.state.data.telefon_mama}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={6} style={{"textAlign":"center"}}>
					<TextField
						variant="outlined"
						margin="dense"
						name="adresa_tata"
						id="adresa_tata"
						label="Adresa tata"
						fullWidth
						autoComplete="adresa_tata"
						value={this.state.data.adresa_tata}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={3} style={{"textAlign":"center"}}>
					<TextField
						required
						variant="outlined"
						margin="dense"
						name="localitate_tata"
						id="localitate_tata"
						label="Localitate"
						fullWidth
						autoComplete="localitate_tata"
						value={this.state.data.localitate_tata}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={3} style={{"textAlign":"center"}}>
					<TextField
						variant="outlined"
						margin="dense"
						name="telefon_tata"
						id="telefon_tata"
						label="Telefon tata"
						fullWidth
						autoComplete="telefom_tata"
						value={this.state.data.telefon_tata}
						onChange={evt => this.updateInputValue(evt,"data")}
					/>
				</Grid>
				<Grid item xs={12} style={{"textAlign":"center"}}>
					{this.props.sess.rol>10&&
						<Button onClick={(evt)=>{this.salveaza_date_parinti()}} color="primary" variant="contained">
							<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Modifica date parinti
						</Button>
					}
				</Grid>
			</Grid>
		);
	}

	date_frati() {
		return(
			<Grid container spacing={1}>
				{(this.state.lista_frati)&&this.state.lista_frati.map((persoana,index) => (
					<Grid key={persoana.id} item xs={12} style={{"textAlign":"center"}}>
						<Grid container spacing={1} style={{"marginBottom":"12px"}}>
							<Grid item xs={4} style={{"textAlign":"center","paddingLeft":"18px"}}>
								<TextField
									required
									variant="outlined"
									margin="dense"
									name="nume_frate"
									label="Nume si prenume"
									fullWidth
									autoComplete="nume_frate"
									value={persoana.nume_frate}
									onChange={evt => this.updateInputValue(evt,"data_frati_lista",index)}
								/>
							</Grid>
							<Grid item xs={3} style={{"textAlign":"center"}}>
								<TextField
									variant="outlined"
									margin="dense"
									name="cnp_frate"
									label="CNP"
									fullWidth
									autoComplete="cnp_frate"
									value={persoana.cnp_frate}
									onChange={evt => this.updateInputValue(evt,"data_frati_lista",index)}
								/>
							</Grid>
							<Grid item xs={5} style={{"textAlign":"center"}}>
								{this.props.sess.rol>10&&
									<React.Fragment>
										<Button onClick={(evt)=>{this.salveaza_date_frati(evt,"modificare",index)}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","width":"132px"}}>
											<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Modifica
										</Button>
										<Button onClick={(evt)=>{this.salveaza_date_frati(evt,"stergere",index)}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","width":"132px"}}>
											<DeleteOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Sterge
										</Button>
									</React.Fragment>
								}
							</Grid>
						</Grid>						
					</Grid>
                ))}
				<Grid item xs={12} style={{"textAlign":"center"}}>
					<Grid container spacing={1} style={{"marginBottom":"12px"}}>
						<Grid item xs={5} style={{"textAlign":"center","paddingLeft":"18px"}}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="nume_frate"
								id="nume_frate"
								label="Nume si prenume"
								fullWidth
								autoComplete="nume_frate"
								value={this.state.data_frati.nume_frate}
								onChange={evt => this.updateInputValue(evt,"data_frati")}
							/>
						</Grid>
						<Grid item xs={4} style={{"textAlign":"center"}}>
							<TextField
								variant="outlined"
								margin="dense"
								name="cnp_frate"
								id="cnp_frate"
								label="CNP"
								fullWidth
								autoComplete="cnp_frate"
								value={this.state.data_frati.cnp_frate}
								onChange={evt => this.updateInputValue(evt,"data_frati")}
							/>
						</Grid>
						<Grid item xs={3} style={{"textAlign":"center"}}>
							{this.props.sess.rol>10&&
								<Button onClick={(evt)=>{this.salveaza_date_frati(evt,"adaugare")}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","width":"156px"}}>
									<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
									Adauga
								</Button>
							}
						</Grid>
					</Grid>						
				</Grid>				
			</Grid>
		);
	}

	date_rude() {
		return(
			<Grid container spacing={1}>
				{(this.state.lista_rude)&&this.state.lista_rude.map((persoana,index) => (
					<Grid key={persoana.id} item xs={12} style={{"textAlign":"center"}}>
						<Grid container spacing={1} style={{"marginBottom":"12px"}}>
							<Grid item xs={4} style={{"textAlign":"center","paddingLeft":"18px"}}>
								<TextField
									required
									variant="outlined"
									margin="dense"
									name="nume_ruda"
									label="Nume si prenume"
									fullWidth
									autoComplete="nume_ruda"
									value={persoana.nume_ruda}
									onChange={evt => this.updateInputValue(evt,"data_rude_lista",index)}
								/>
							</Grid>
							<Grid item xs={3} style={{"textAlign":"center"}}>
								<TextField
									variant="outlined"
									margin="dense"
									name="cnp_ruda"
									label="CNP"
									fullWidth
									autoComplete="cnp_ruda"
									value={persoana.cnp_ruda}
									onChange={evt => this.updateInputValue(evt,"data_rude_lista",index)}
								/>
							</Grid>
							<Grid item xs={1}>
								<TextField
									required
									variant="outlined"
									margin="dense"
									name="grad_rudenie"
									select
									label="Grad"
									fullWidth
									SelectProps={{
										native: true,
									}}
									value={persoana.grad_rudenie}
									onChange={evt => this.updateInputValue(evt,"data_rude_lista",index)}
								>
									<option/>
									{this.state.grade_rudenie_list.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid item xs={4} style={{"textAlign":"center"}}>
								{this.props.sess.rol>10&&
									<React.Fragment>
										<Button onClick={(evt)=>{this.salveaza_date_rude(evt,"modificare",index)}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","width":"120px"}}>
											<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Modifica
										</Button>
										<Button onClick={(evt)=>{this.salveaza_date_rude(evt,"stergere",index)}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","width":"120px"}}>
											<DeleteOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Sterge
										</Button>
									</React.Fragment>
								}
							</Grid>
							<Grid item xs={5} style={{"textAlign":"center","paddingLeft":"18px"}}>
								<TextField
									required
									variant="outlined"
									margin="dense"
									name="adresa_ruda"
									label="Adresa"
									fullWidth
									autoComplete="adresa_ruda"
									value={persoana.adresa_ruda}
									onChange={evt => this.updateInputValue(evt,"data_rude_lista",index)}
								/>
							</Grid>
							<Grid item xs={3} style={{"textAlign":"center"}}>
								<TextField
									required
									variant="outlined"
									margin="dense"
									name="localitate_ruda"
									label="Localitate"
									fullWidth
									autoComplete="localitate_ruda"
									value={persoana.localitate_ruda}
									onChange={evt => this.updateInputValue(evt,"data_rude_lista",index)}
								/>
							</Grid>
							<Grid item xs={4} style={{"textAlign":"center","paddingLeft":"18px"}}>
								<TextField
									required
									variant="outlined"
									margin="dense"
									name="telefon_ruda"
									label="Telefon"
									fullWidth
									autoComplete="telefon_ruda"
									value={persoana.telefon_ruda}
									onChange={evt => this.updateInputValue(evt,"data_rude_lista",index)}
								/>
							</Grid>
						</Grid>						
					</Grid>
                ))}
				<Grid item xs={12} style={{"textAlign":"center"}}>
					<Grid container spacing={1} style={{"marginBottom":"12px"}}>
						<Grid item xs={4} style={{"textAlign":"center","paddingLeft":"18px"}}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="nume_ruda"
								id="nume_ruda"
								label="Nume si prenume"
								fullWidth
								autoComplete="nume_ruda"
								value={this.state.data_rude.nume_ruda}
								onChange={evt => this.updateInputValue(evt,"data_rude")}
							/>
						</Grid>
						<Grid item xs={3} style={{"textAlign":"center"}}>
							<TextField
								variant="outlined"
								margin="dense"
								name="cnp_ruda"
								id="cnp_ruda"
								label="CNP"
								fullWidth
								autoComplete="cnp_ruda"
								value={this.state.data_rude.cnp_ruda}
								onChange={evt => this.updateInputValue(evt,"data_rude")}
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="grad_rudenie"
								id="grad_rudenie"
								select
								label="Grad"
								fullWidth
								SelectProps={{
									native: true,
								}}
								value={this.state.data_rude.grad_rudenie}
								onChange={evt => this.updateInputValue(evt,"data_rude")}
							>
								<option/>
								{this.state.grade_rudenie_list.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
						<Grid item xs={3} style={{"textAlign":"center"}}>
							{this.props.sess.rol>10&&
								<Button onClick={(evt)=>{this.salveaza_date_rude(evt,"adaugare")}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","width":"156px"}}>
									<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
									Adauga
								</Button>
							}
						</Grid>
						<Grid item xs={6} style={{"textAlign":"center","paddingLeft":"18px"}}>
							<TextField
								variant="outlined"
								margin="dense"
								name="adresa_ruda"
								id="adresa_ruda"
								label="Adresa"
								fullWidth
								autoComplete="adresa_ruda"
								value={this.state.data_rude.adresa_ruda}
								onChange={evt => this.updateInputValue(evt,"data_rude")}
							/>
						</Grid>
						<Grid item xs={3} style={{"textAlign":"center"}}>
							<TextField
								required
								variant="outlined"
								margin="dense"
								name="localitate_ruda"
								id="localitate_ruda"
								label="Localitate"
								fullWidth
								autoComplete="localitata_ruda"
								value={this.state.data_rude.localitate_ruda}
								onChange={evt => this.updateInputValue(evt,"data_rude")}
							/>
						</Grid>
						<Grid item xs={3} style={{"textAlign":"center","paddingLeft":"12px","paddingRight":"12px"}}>
							<TextField
								variant="outlined"
								margin="dense"
								name="telefon_ruda"
								id="telefon_ruda"
								label="Telefon"
								fullWidth
								autoComplete="telefon_ruda"
								value={this.state.data_rude.telefon_ruda}
								onChange={evt => this.updateInputValue(evt,"data_rude")}
							/>
						</Grid>
					</Grid>						
				</Grid>				
			</Grid>
		);
	}

	render() {
		if(this.state.is_loaded===1) {
			return(
				<div style={{"width":"870px","height":"84vh"}}>
					<Fieldset legend="Date parinti" className="text_center" style={{"padding":"12px","margin":"0px","paddingTop":"0px"}}>
						{this.date_parinti()}
					</Fieldset>
					<Fieldset legend="Date frati/surori" className="text_center" style={{"padding":"12px","margin":"0px","paddingTop":"0px"}}>
						{this.date_frati()}
					</Fieldset>
					<Fieldset legend="Date rude" className="text_center" style={{"padding":"12px","margin":"0px","paddingTop":"0px"}}>
						{this.date_rude()}
					</Fieldset>
					{this.message()}
				</div>
			)			
		}else{
			return(
				<LinearProgress/>
			);
		}
	}
}

export default Familie;
