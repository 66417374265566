import React from 'react';
import {Fieldset} from 'primereact/fieldset';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {fetch_url_post,add_to_array,remove_from_array,find_index_in_array,replace_element_in_array,clone_object} from "../../Sources/js/app.js";
import RaportExcel from './RaportExcel';

class RaportRecuperare extends React.Component {
	constructor(props) {
		super(props);
		this.data=new Date().toISOString().slice(0,10).replace('T', ' ');
		let tmp_data=this.data.split("-");
		this.data=tmp_data[2]+"-"+tmp_data[1]+"-"+tmp_data[0];
		this.state={
            is_loaded: 0,
			raport: [],
			message_open: false,
			message_text: "",
        };
        this.oper="";
		this.modif_el={};
		this.url="raport_recuperare.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
	}
		
	success_handler(result) {
		if(this.oper==="raport") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					raport: result.data.raport,
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});				
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="raport";
		var data={
			oper: this.oper,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

    render() {
		if(this.state.is_loaded===1) {
			return(
				<div className="fix_height">
					<Fieldset legend="Raport recuperare" name="tabel_items" id="tabel_items" className="text_center" style={{"padding":"12px","margin":"0px","flex":"1 1 auto","paddingTop":"6px"}}>
						<Grid container spacing={1} style={{"marginBottom":"3px","marginTop":"-12px"}}>
							<Grid item xs={6}>
								&nbsp;
							</Grid>
                            <Grid item xs={2}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={2}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={2}>
                                {!!(this.state.raport.length>0) &&
                                    <RaportExcel nume={"raport_recuperare"} data={this.state.raport}/>
                                }
                            </Grid>
						</Grid>
						<DataTable ref={(el) => this.dt = el} 
							value={this.state.raport}
							scrollable={true}
							paginator={true}
							rows={17}
							selectionMode="single"
						>
							<Column field="nume_prenume" header="Nume si prenume" style={{"width":"23%"}} filter={true} filterMatchMode="contains"/>
							<Column field="cod_intern" header="Cod intern" style={{"width":"12%"}} filter={true} filterMatchMode="contains"/>
							<Column field="data_nasterii" header="Data nasterii" style={{"width":"11%"}} filter={true} filterMatchMode="contains"/>
							<Column field="varsta" header="Varsta" style={{"width":"6%"}}/>
							<Column field="nume_locatie_recuperare" header="Locul recuperarii" style={{"width":"34%"}} filter={true} filterMatchMode="contains"/>
							<Column field="grad_handicap" header="Grad handic." style={{"width":"10%"}}/>
						</DataTable>
					</Fieldset>
					{this.message()}
				</div>
			)			
		}else{
			return(
				<LinearProgress/>
			);
		}
	}
}

export default RaportRecuperare;
