import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudDownloadIcon from '@material-ui/icons//CloudDownload';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import {fetch_url_post,clone_object,upload_file,fetch_download} from "../../Sources/js/app.js";

class FisiereCopii extends React.Component {
	constructor(props) {
		super(props);
		this.reset_data={
			comentariu: "",
		}
		this.state={
            is_loaded: 0,
			lista_fisiere: [],
			message_open: false,
			message_text: "",
			data:clone_object(this.reset_data),
			fisier: {},
        };
        this.oper="";
		this.modif_el={};
		this.url="fisiere_copii.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.salveaza_comentariu=this.salveaza_comentariu.bind(this);
		this.incarca=this.incarca.bind(this);
		this.descarca=this.descarca.bind(this);
	}
		
	success_handler(result) {
		if((this.oper==="lista")||(this.oper==="incarca_fisier")||(this.oper==="sterge_fisier")) {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					lista_fisiere: result.data.lista,
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="salveaza_comentariu") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					message_open: true,
					message_text: "Descrierea a fost salvata cu succes!",
				});				
			}else{
				this.setState({
					message_open: true,
					message_text: result.error,
				});				
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="lista";
		var data={
			oper: this.oper,
			id_copil: this.props.copil.id,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	updateInputValue(evt,index) {
		let tmp;
		if(index===undefined) {
			tmp=this.state.data;
			tmp[evt.target.name]=evt.target.value;
			this.setState({
				data: tmp,
			});
		}else{
			tmp=this.state.lista_fisiere;
			tmp[index][evt.target.name]=evt.target.value;
			this.setState({
				lista_fisiere: tmp,
			});
		}
	}

	incarca() {
		if(!this.state.data.fisier==="") {
			this.setState({
				message_open: true,
				message_text: "Selectati un fisier!",
			});	
			return false;			
		}
		this.oper="incarca_fisier";
		upload_file(this.url+"&oper="+this.oper+"&comentariu="+this.state.data.comentariu+"&id_copil="+this.props.copil.id,this.state.fisier,this.success_handler,this.fail_handler);				
	}

	salveaza_comentariu(id,index) {
		this.oper="salveaza_comentariu";
		var data={
			oper: this.oper,
			id: id,
			comentariu: this.state.lista_fisiere[index].comentariu,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);				
	}

	sterge(id) {
		if(window.confirm("Sigur doriti stegerea?\n\n\nOperatiunea este ireversibila!\n\n\n")) {
			this.oper="sterge_fisier";
			var data={
				oper: this.oper,
				id: id,
				id_copil: this.props.copil.id,
			};
			fetch_url_post(this.url,data,this.success_handler,this.fail_handler);				
		}
	}

	descarca(nume,nume_pe_disk) {
		fetch_download(nume,nume_pe_disk,this.success_handler);
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	render() {
		if(this.state.is_loaded===1) {
			return(
				<div style={{"width":"840px","height":"75vh"}}>
					{this.state.lista_fisiere && this.state.lista_fisiere.length>0 && this.state.lista_fisiere.map((fisier,index) =>
						<Paper style={{"marginBottom":"12px","marginTop":"12px","paddingLeft":"12px","paddingRight":"12px"}} key={fisier.id}>
							<Grid container spacing={1}>
								<Grid item xs={12} style={{"textAlign":"center"}}>
									<p>Fisier incarcat la data {fisier.data_introducerii} de catre {fisier.nume_utilizator}</p>
								</Grid>
								<Grid item xs={12} style={{"textAlign":"center"}}>
									<p>Nume fisier <b>{fisier.nume_fisier}</b></p>
								</Grid>
								<Grid item xs={12} style={{"textAlign":"center","paddingRight":"30px","paddingLeft":"30px"}}>
									<TextField
										variant="outlined"
										margin="dense"
										name="comentariu"
										id="comentariu"
										label="Descriere"
										fullWidth
										multiline={true}
										rows={3}
										value={fisier.comentariu}
										onChange={evt => this.updateInputValue(evt,index)}
									/>
								</Grid>
								<Grid item xs={12} style={{"textAlign":"center"}}>
									<Button onClick={(evt)=>{this.descarca(fisier.nume_fisier,fisier.nume_pe_disk)}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","marginBottom":"12px"}}>
										<CloudDownloadIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
										Descarca
									</Button>
									<Button onClick={(evt)=>{this.salveaza_comentariu(fisier.id,index)}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","marginBottom":"12px"}}>
										<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
										Salveaza descriere
									</Button>
									<Button onClick={(evt)=>{this.sterge(fisier.id)}} color="secondary" variant="contained" style={{"marginTop":"9px","marginBottom":"12px"}}>
										<DeleteOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
										Sterge
									</Button>
								</Grid>
							</Grid>
						</Paper>
					)}
					{!!((!this.state.lista_fisiere)||(this.state.lista_fisiere.length==0)) &&
						<Paper style={{"marginBottom":"12px","marginTop":"30px"}}>
							<Grid container spacing={1}>
								<Grid item xs={12} style={{"textAlign":"center"}}>
									<p style={{"fontWeight":"603","fontSize":"15px"}}>Nu exista fisiere incarcate.</p>
								</Grid>
							</Grid>						
						</Paper>
					}
					<Grid container spacing={1} style={{"marginTop":"48px"}}>
						{!!(!this.state.fisier.name) &&
							<Grid item xs={12} style={{"textAlign":"center"}}>
								<label htmlFor="upload-photo">
									<input
										style={{ display: "none" }}
										id="upload-photo"
										name="upload-photo"
										type="file"
										onChange={(evt)=>{this.setState({fisier: evt.target.files[0]})}}
									/>
									<Fab
										color="primary"
										size="small"
										component="span"
										aria-label="add"
										variant="extended"
										style={{"padding":"30px","marginTop":"12px","marginBottom":"12px"}}
									>
										<AddCircleOutlineIcon style={{"marginRight":"12px"}} />Incarca fisier
									</Fab>
								</label>
							</Grid>
						}
						{!!(this.state.fisier.name) &&
							<React.Fragment>
								<Grid item xs={12} style={{"textAlign":"center","padding":"12px"}}>
									<p style={{"fontSize":"15px"}}>Fisier selectat: <b>{this.state.fisier.name}</b></p>
								</Grid>
								<Grid item xs={12} style={{"textAlign":"center","paddingRight":"30px","paddingLeft":"30px"}}>
									<TextField
										variant="outlined"
										margin="dense"
										name="comentariu"
										id="comentariu"
										label="Descriere"
										fullWidth
										multiline={true}
										rows={3}
										value={this.state.data.comentariu}
										onChange={evt => this.updateInputValue(evt)}
									/>
								</Grid>
								<Grid item xs={12} style={{"textAlign":"center","padding":"12px"}}>
									<Button onClick={(evt)=>{this.incarca()}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","marginBottom":"24px"}}>
										<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
										Incarca
									</Button>
									<Button onClick={(evt)=>{this.setState({fisier: {}})}} color="primary" variant="contained" style={{"marginTop":"9px","marginBottom":"24px"}}>
										<RefreshIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
										Reseteaza
									</Button>
								</Grid>
							</React.Fragment>
						}
					</Grid>
					{this.message()}
				</div>
			)			
		}else{
			return(
				<LinearProgress/>
			);
		}
	}
}

export default FisiereCopii;



