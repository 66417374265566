import React from 'react';
import PropTypes from 'prop-types';
import {Crypt_pass} from "../Sources/js/app.js";
import {fetch_url_post} from "../Sources/js/app.js";
import LoginFormM from "./Material/LoginForm.js";
 
class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state={utl: 0,ps: 0,is_auth: 0};
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.auth=this.auth.bind(this);
	}
    
    success_handler(result) {
    	this.props.updateAuthState(result);
    }

    fail_handler() {
    	this.props.updateAuthState({is_auth:0,sessid:"NA"});
    }
    
    auth() {
		if((this.state.utl===0)||(this.state.ps===0)) {
    		return false;
    	}
    	if((this.state.utl===undefined)||(this.state.ps===undefined)) {
    		return false;
    	}
    	if((this.state.utl===null)||(this.state.ps===null)) {
    		return false;
    	}
    	if((this.state.utl.length<3)||(this.state.ps<3)) {
    		return false;
    	}
		var mdp=Crypt_pass(this.state.utl,this.state.ps);
		var url="auth.asp";
		var data={};
		data.op="login";
		data.utl=this.state.utl;
		data.mdp=mdp;
		fetch_url_post(url,data,this.success_handler,this.fail_handler);
    }

    updateInputValue(evt) {
		this.setState({[evt.target.name]: evt.target.value});
    }

	render() {
		return (
			<LoginFormM updateInputValue={this.updateInputValue} auth={this.auth}/>
		);
	}
}

LoginForm.propTypes = {
	onClick: PropTypes.func
};

LoginForm.defaultProps = {

};

export default LoginForm;
