import React from "react";
import ReactExport from "react-export-excel";
import Button from '@material-ui/core/Button';
import BorderAllIcon from '@material-ui/icons/BorderAll';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class RaportExcel extends React.Component {
    render() {
        if(this.props.nume==="copii") {
            return (
                <ExcelFile element={
                                <Button color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","float":"right"}}>
                                    <BorderAllIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
                                    Export Excel
                                </Button>
                }>
                    <ExcelSheet data={this.props.data} name="Copii">
                        <ExcelColumn label="Cod intern" value="cod_intern"/>
                        <ExcelColumn label="Nume" value="nume"/>
                        <ExcelColumn label="Prenume" value="prenume"/>
                        <ExcelColumn label="Varsta" value="varsta_ani_luni"/>
                        <ExcelColumn label="Nume mama" value="nume_mama"/>
                        <ExcelColumn label="CNP mama" value="cnp_mama"/>
                        <ExcelColumn label="Localitate mama" value="localitate_mama"/>
                        <ExcelColumn label="Nume tata" value="nume_tata"/>
                        <ExcelColumn label="CNP tata" value="cnp_tata"/>
                        <ExcelColumn label="Localitate tata" value="localitate_tata"/>
                        <ExcelColumn label="CN serie" value="cn_serie"/>
                        <ExcelColumn label="CN numar" value="cn_numar"/>
                        <ExcelColumn label="CI serie" value="ci_serie"/>
                        <ExcelColumn label="CI numar" value="ci_numar"/>
                        <ExcelColumn label="CN serie" value="cn_serie"/>
                        <ExcelColumn label="Handicap" value="handicap_nume"/>
                        <ExcelColumn label="Grad handicap" value="grad_handicap"/>
                        <ExcelColumn label="Tip certificat" value="tip_certificat_handicap"/>
                        <ExcelColumn label="Data incetare recuperare" value="data_incetare_recuperare"/>
                        <ExcelColumn label="Observatii" value="observatii"/>
                    </ExcelSheet>
                </ExcelFile>
            );
        }
        if(this.props.nume==="masuri") {
            return (
                <ExcelFile element={
                                <Button color="primary" variant="contained" style={{}}>
                                    <BorderAllIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
                                    Export Excel
                                </Button>
                }>
                    <ExcelSheet data={this.props.data} name="Masuri">
                        <ExcelColumn label="Cod intern" value="cod_intern"/>
                        <ExcelColumn label="Nume copil" value="nume_copil"/>
                        <ExcelColumn label="Varsta" value="varsta_ani_luni"/>
                        <ExcelColumn label="Varsta la data masurii" value="varsta_data_masura_ani_luni"/>
                        <ExcelColumn label="Sex" value="sex"/>
                        <ExcelColumn label="Masura" value="masura"/>
                        <ExcelColumn label="Tip masura" value="tip"/>
                        <ExcelColumn label="Fel masura" value="fel"/>
                        <ExcelColumn label="Locatie copil" value="locatie_copil"/>
                        <ExcelColumn label="Decident" value="decident"/>
                        <ExcelColumn label="Judet" value="judet"/>
                        <ExcelColumn label="Data act" value="data_act"/>
                        <ExcelColumn label="Numar act" value="nr_act"/>                        
                        <ExcelColumn label="Handicap" value="handicap_nume"/>
                        <ExcelColumn label="Grad handicap" value="grad_handicap"/>
                        <ExcelColumn label="Localitate" value="localitate_locatie_copil"/>
                        <ExcelColumn label="Activa" value="nume_activa"/>
                        <ExcelColumn label="Motiv" value="motiv"/>
                        <ExcelColumn label="Data inactivarii" value="data_inactivarii"/>
                        <ExcelColumn label="Observatii" value="observatii_inactivare"/>
                    </ExcelSheet>
                </ExcelFile>
            );
        }
        if(this.props.nume==="raport_recuperare") {
            return (
                <ExcelFile element={
                                <Button color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","float":"right"}}>
                                    <BorderAllIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
                                    Export Excel
                                </Button>
                }>
                    <ExcelSheet data={this.props.data} name="raport_recuperare">
                        <ExcelColumn label="Cod intern" value="cod_intern"/>
                        <ExcelColumn label="Nume" value="nume"/>
                        <ExcelColumn label="Prenume" value="prenume"/>
                        <ExcelColumn label="Data nasterii" value="data_nasterii"/>
                        <ExcelColumn label="Varsta" value="varsta"/>
                        <ExcelColumn label="Locul nasterii" value="locul_nasterii"/>
                        <ExcelColumn label="Nr. certificat handicap" value="nr_certificat_handicap"/>
                        <ExcelColumn label="Data certificat handicap" value="data_certificat_handicap"/>
                        <ExcelColumn label="Data incepere recuperare" value="data_start_recuperare"/>
                        <ExcelColumn label="Luni recuperare efectuate" value="durata_recuperare"/>
                        <ExcelColumn label="Valabilitate" value="valabilitate_certificat_handicap"/>
                        <ExcelColumn label="Face recuperare" value="face_recuperare"/>
                        <ExcelColumn label="Grad handicap" value="grad_handicap"/>
                        <ExcelColumn label="Nivel handicap 1" value="nivel_handicap_1"/>
                        <ExcelColumn label="Nivel handicap 2" value="nivel_handicap_2"/>
                        <ExcelColumn label="Nivel handicap 3" value="nivel_handicap_3"/>
                        <ExcelColumn label="Tip certificat handicap" value="tip_certificat_handicap"/>
                        <ExcelColumn label="Invatamant de masa" value="invatamant_de_masa"/>
                        <ExcelColumn label="Invatamant special" value="invatamant_special"/>
                        <ExcelColumn label="Altele" value="observatii"/>
                        <ExcelColumn label="Ajutor de stat" value="ajutor_stat"/>
                        <ExcelColumn label="Locatie recuperare" value="nume_locatie_recuperare"/>
                        <ExcelColumn label="Localitate locatie recuperare" value="localitate_locatie_recuperare"/>
                        <ExcelColumn label="Tip locatie" value="masura"/>
                        <ExcelColumn label="Data incetare recuperare" value="data_incetare_recuperare"/>
                        <ExcelColumn label="Observatii" value="observatii"/>
                    </ExcelSheet>
                </ExcelFile>
            );
        }
        if(this.props.nume==="asistenti_maternali") {
            return (
                <ExcelFile element={
                                <Button color="primary" variant="contained" style={{"marginRight":"9px","float":"right"}}>
                                    <BorderAllIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
                                    Export Excel
                                </Button>
                }>
                    <ExcelSheet data={this.props.data} name="Copii">
                        <ExcelColumn label="Nume" value="nume"/>
                        <ExcelColumn label="Prenume" value="prenume"/>
                        <ExcelColumn label="Adresa" value="adresa"/>
                        <ExcelColumn label="CNP" value="cnp"/>
                        <ExcelColumn label="Localitate" value="localitate"/>
                        <ExcelColumn label="Telefon" value="telefon"/>
                        <ExcelColumn label="Data creare" value="data_introducerii"/>                        
                        <ExcelColumn label="Activ" value="activ_nume"/>
                    </ExcelSheet>
                </ExcelFile>
            );
        }
        if(this.props.nume==="asistenti_sociali") {
            return (
                <ExcelFile element={
                                <Button color="primary" variant="contained" style={{"float":"right","marginBottom":"9px"}}>
                                    <BorderAllIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
                                    Export Excel
                                </Button>
                }>
                    <ExcelSheet data={this.props.data} name="Copii">
                        <ExcelColumn label="Nume" value="nume"/>
                        <ExcelColumn label="Prenume" value="prenume"/>
                        <ExcelColumn label="Adresa" value="adresa"/>
                        <ExcelColumn label="Localitate" value="localitate"/>
                        <ExcelColumn label="Telefon" value="telefon"/>
                        <ExcelColumn label="Data creare" value="data_introducerii"/>                        
                        <ExcelColumn label="Activ" value="activ_nume"/>
                    </ExcelSheet>
                </ExcelFile>
            );
        }
    }
}

export default RaportExcel;
