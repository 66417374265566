import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import {fetch_url_post,clone_object} from "../../Sources/js/app.js";

class Comentarii extends React.Component {
	constructor(props) {
		super(props);
		this.reset_data={
			comentariu: "",
		}
		this.state={
            is_loaded: 0,
			lista_comentarii: [],
			message_open: false,
			message_text: "",
			data:clone_object(this.reset_data),
        };
        this.oper="";
		this.modif_el={};
		this.url="comentarii.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.salveaza=this.salveaza.bind(this);
	}
		
	success_handler(result) {
		if((this.oper==="lista")||(this.oper==="salveaza")||(this.oper==="sterge")) {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					lista_comentarii: result.data.lista,
					data:clone_object(this.reset_data),
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});				
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="lista";
		var data={
			oper: this.oper,
			id_copil: this.props.copil.id,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	updateInputValue(evt) {
		let tmp;
		tmp=this.state.data;
		tmp[evt.target.name]=evt.target.value;
		this.setState({
			data: tmp,
		});
	}

	salveaza() {
		if(this.state.data.comentariu==="") {
			this.setState({
				message_open: true,
				message_text: "Completati comentariul!",
			});	
			return false;			
		}
		this.oper="salveaza";
		var data={
			oper: this.oper,
			id_copil: this.props.copil.id,
			data: this.state.data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);				
	}

	sterge(id) {
		if(window.confirm("Sigur doriti stegerea?\n\n\nOperatiunea este ireversibila!\n\n\n")) {
			this.oper="sterge";
			var data={
				oper: this.oper,
				id: id,
				id_copil: this.props.copil.id,
			};
			fetch_url_post(this.url,data,this.success_handler,this.fail_handler);				
		}
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	render() {
		if(this.state.is_loaded===1) {
			return(
				<div style={{"width":"840px","height":"75vh"}}>
					{this.state.lista_comentarii && this.state.lista_comentarii.length>0 && this.state.lista_comentarii.map((comentariu) =>
						<Paper style={{"marginBottom":"12px","marginTop":"30px","paddingLeft":"12px","paddingRight":"12px"}} key={comentariu.id}>
							<Grid container spacing={1}>
								<Grid item xs={2} style={{"textAlign":"center"}}>
									<p>{comentariu.data_introducerii}</p>
								</Grid>
								<Grid item xs={8} style={{"textAlign":"center"}}>
									<TextField
										required
										variant="outlined"
										margin="dense"
										label="Comentariu"
										disabled={true}
										fullWidth
										multiline={true}
										rows={3}
										value={comentariu.comentariu}
									/>
								</Grid>
								<Grid item xs={2} style={{"textAlign":"center"}}>
									<Button onClick={(evt)=>{this.sterge(comentariu.id)}} color="secondary" variant="contained" style={{"marginTop":"9px"}}>
										<DeleteOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
										Sterge
									</Button>
								</Grid>
							</Grid>
						</Paper>
					)}
					{!!((!this.state.lista_comentarii)||(this.state.lista_comentarii.length==0)) &&
						<Paper style={{"marginBottom":"12px","marginTop":"30px"}}>
							<Grid container spacing={1}>
								<Grid item xs={12} style={{"textAlign":"center"}}>
									<p style={{"fontWeight":"603","fontSize":"15px"}}>Nu exista comentarii adaugate.</p>
								</Grid>
							</Grid>						
						</Paper>
					}
					<Paper style={{"marginBottom":"12px","marginTop":"48px","paddingLeft":"12px","paddingRight":"12px"}}>
						<Grid container spacing={1}>
							<Grid item xs={10} style={{"textAlign":"center"}}>
								<TextField
									variant="outlined"
									margin="dense"
									name="comentariu"
									id="comentariu"
									label="Comentariu"
									fullWidth
									multiline={true}
									rows={3}
									value={this.state.data.comentariu}
									onChange={evt => this.updateInputValue(evt)}
								/>
							</Grid>
							<Grid item xs={2} style={{"textAlign":"center"}}>
								<Button onClick={(evt)=>{this.salveaza()}} color="primary" variant="contained" style={{"marginTop":"9px"}}>
									<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
									Adauga
								</Button>
							</Grid>
						</Grid>
					</Paper>
					{this.message()}
				</div>
			)			
		}else{
			return(
				<LinearProgress/>
			);
		}
	}
}

export default Comentarii;


