import React, { Component } from 'react';
import './Sources/css/App.css';
import LoginForm from './Components/LoginForm';
import Dashboard from './Components/Dashboard';
import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

class App extends Component {
    constructor(props) {
		super(props);
		this.state={is_auth: 0,first: 1,sessid: "NA"};
		this.updateAuthState = this.updateAuthState.bind(this);
		this.logout = this.logout.bind(this);		
	}

    updateAuthState(auth_state_obj) {
		auth_state_obj.first=0;
		this.setState(auth_state_obj);
    }

	logout() {
		this.setState({is_auth: 0});
	}

    render() {
		if(this.state.is_auth===1) {
	    	return(<Dashboard sess={this.state} logout={this.logout}/>);
		}else{	    
			if(this.state.first===1) {
//				return(<MainPage sess={this.state}/>);
				return(<LoginForm visible="1" updateAuthState={this.updateAuthState}/>);
	    	}else{
	    		return(<LoginForm visible="1" updateAuthState={this.updateAuthState} nauth="1"/>);	    		
	    	}
		}
    }
}

export default App;
