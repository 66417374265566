import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Fieldset} from 'primereact/fieldset';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import ReorderIcon from '@material-ui/icons/Reorder';
import BorderAllIcon from '@material-ui/icons/BorderAll';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import Familie from './Familie';
import Comentarii from './Comentarii';
import FisiereCopii from './FisiereCopii';
import RaportExcel from './RaportExcel';
import {fetch_url_post,add_to_array,remove_from_array,find_index_in_array,replace_element_in_array,clone_object} from "../../Sources/js/app.js";

class Copii extends React.Component {
	constructor(props) {
		super(props);
		this.data=new Date().toISOString().slice(0,10).replace('T', ' ');
		let tmp_data=this.data.split("-");
		this.data=tmp_data[2]+"-"+tmp_data[1]+"-"+tmp_data[0];
		this.data_slash=tmp_data[2]+"/"+tmp_data[1]+"/"+tmp_data[0];		
		this.reset_data={
			id: 0,
			cod_intern: "",
			nume: "",
			prenume: "",
			cn_serie: "",
			cn_numar: "",
			ci_serie: "",
			ci_numar: "",
			handicap: 0,
			grad_handicap: 0,
			data_introducerii: this.data,
			locul_nasterii: "",
			nr_certificat_handicap: "",
			data_certificat_handicap: this.data_slash,
			valabilitate_certificat_handicap: "",
			tip_certificat_handicap: 0,
			nivel_handicap_1: "",
			nivel_handicap_2: "",
			nivel_handicap_3: "",
			invatamant_de_masa: "",
			invatamant_special: "",
			ajutor_stat: 0,
			observatii: "",
			recuperare: 0,
			face_recuperare: 0,
			data_incetare_recuperare: "",
			tip_recuperare: "",
			id_familie_recuperare: 0,
			id_centru_recuperare: 0,
			id_asistent_recuperare: 0,
			data_start_recuperare: this.data_slash,
			nr_dispozitie_recuperare: "",
		}
		this.reset_data_cautare={
			cautare_cod_intern: "",
			cautare_nume: "",
			cautare_prenume: "",
			cautare_handicap: "",
			cautare_grad_handicap: "",
			cautare_nume_mama: "",
			cautare_nume_tata: "",
			cautare_id_frati: "",
			cautare_id_ruda: "",			
		}
		this.state={
            is_loaded: 0,
			lista_copii: [],
			message_open: false,
			message_text: "",
			selected_el: undefined,
			boolean_list: [
				{
					label: "DA",
					value: 1,
				},
				{
					label: "NU",
					value: 0,
				},				
			],
			grad_handicap_list: [
				{
					label: "-",
					value: "-",
				},
				{
					label: "USOR",
					value: "USOR",
				},
				{
					label: "MEDIU",
					value: "MEDIU",
				},				
				{
					label: "ACCENTUAT",
					value: "ACCENTUAT",
				},
				{
					label: "GRAV",
					value: "GRAV",
				},
			],
			tip_recuperare_list: [
				{
					label: "-",
					value: "-",
				},
				{
					label: "FAMILIE",
					value: "FAMILIE",
				},
				{
					label: "REZIDENTIAL",
					value: "REZIDENTIAL",
				},
				{
					label: "AMP",
					value: "AMP",
				},
			],
			ajutor_stat_list: [
				{
					label: "-",
					value: "-",
				},
				{
					label: "INDEMNIZATIE",
					value: "INDEMNIZATIE",
				},
				{
					label: "ASISTENT PERSONAL",
					value: "ASISTENT PERSONAL",
				},				
			],
			tip_certificat_handicap_list :[
				{
					label: "-",
					value: "-",
				},
				{
					label: "NOU",
					value: "NOU",
				},
				{
					label: "REEVALUARE",
					value: "REEVALUARE",
				}
			],
			data:clone_object(this.reset_data),
			data_cautare:clone_object(this.reset_data_cautare),
			dupa_modificare: 0,
			lista_mama: [],
			lista_tata: [],
			lista_frati: [],			
			lista_rude: [],
			dialog_familie_open: false,
			dialog_comentarii_open: false,
			dialog_fisiere_open: false,
			export_excel: 0,
			anchorEl_operatiuni: null,
			lista_familii_recuperare: [],
			lista_centre_recuperare: [],
			lista_asistenti_recuperare: [],
        };
        this.oper="";
		this.modif_el={};
		this.url="copii.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.selectare=this.selectare.bind(this);
		this.forma_operatiuni=this.forma_operatiuni.bind(this);
		this.salveaza_date=this.salveaza_date.bind(this);
		this.sterge_date=this.sterge_date.bind(this);
		this.reseteaza=this.reseteaza.bind(this);
		this.forma_operatiuni_butoane=this.forma_operatiuni_butoane.bind(this);
		this.forma_cautare=this.forma_cautare.bind(this);
		this.updateInputValueCautare=this.updateInputValueCautare.bind(this);
		this.cautare_copil=this.cautare_copil.bind(this);
		this.reseteaza_date_cautare=this.reseteaza_date_cautare.bind(this);
		this.dialog_familie=this.dialog_familie.bind(this);
		this.dialog_comentarii=this.dialog_comentarii.bind(this);
		this.dialog_fisiere=this.dialog_fisiere.bind(this);
		this.updateInputValueCautare_autocomplete=this.updateInputValueCautare_autocomplete.bind(this);
		this.forma_cautare_butoane=this.forma_cautare_butoane.bind(this);
		this.render_operatiuni_menu=this.render_operatiuni_menu.bind(this);
		this.familie=this.familie.bind(this);
		this.comentarii=this.comentarii.bind(this);
		this.fisiere=this.fisiere.bind(this);
	}
		
	success_handler(result) {
		if(this.oper==="initializare") {
			this.oper="";
			if(result.ok===1) {
				if(!result.data.lista_mama) {
					result.data.lista_mama=[];
				}
				if(!result.data.lista_tata) {
					result.data.lista_tata=[];
				}
				if(!result.data.lista_frati) {
					result.data.lista_frati=[];
				}
				if(!result.data.lista_rude) {
					result.data.lista_rude=[];
				}
				if(!result.data.lista_familii_recuperare) {
					result.data.lista_familii_recuperare=[];
				}
				if(!result.data.lista_centre_recuperare) {
					result.data.lista_centre_recuperare=[];
				}
				if(!result.data.lista_asistenti_recuperare) {
					result.data.lista_asistenti_recuperare=[];
				}
				this.setState({
					is_loaded: 1,
					lista_mama: result.data.lista_mama,
					lista_tata: result.data.lista_tata,
					lista_frati: result.data.lista_frati,
					lista_rude: result.data.lista_rude,
					lista_familii_recuperare: result.data.lista_familii_recuperare,
					lista_centre_recuperare: result.data.lista_centre_recuperare,
					lista_asistenti_recuperare: result.data.lista_asistenti_recuperare,
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="cautare") {
			this.oper="";
			if(result.ok===1) {
				let export_excel=0;
				if(result.data.lista_copii.length>0) {
					export_excel=1;
				}
				this.setState({
					is_loaded: 1,
					lista_copii: result.data.lista_copii,
					export_excel: export_excel,
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="salveaza_adaugare") {
			this.oper="";
			if(result.ok===1) {
				let new_item=this.state.data;
				new_item.id=result.new_id;
				this.setState({
					is_loaded: 1,
					new_id: result.new_id,
					lista_copii: add_to_array(this.state.lista_copii,new_item),
					data: clone_object(this.reset_data),
					message_open: true,
					message_text: "Copilul a fost adaugat cu succes!",
					anchorEl_operatiuni: null,
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
					anchorEl_operatiuni: null,
				});
			}
		}
		if(this.oper==="salveaza_modificare") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					lista_copii: replace_element_in_array(this.state.lista_copii,this.state.selected_el,this.state.data),
//					data: clone_object(this.reset_data),
					dupa_modificare: 1,
					selected_el: this.state.lista_copii[find_index_in_array(this.state.lista_copii,this.state.selected_el)],
					message_open: true,
					message_text: "Datele copilului au fost modificate cu succes!",
					anchorEl_operatiuni: null,
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
					anchorEl_operatiuni: null,
				});
			}
		}
		if(this.oper==="salveaza_stergere") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					data: clone_object(this.reset_data),
					lista_copii: remove_from_array(this.state.lista_copii,this.state.selected_el),
					selected_el: undefined,
					message_open: true,
					message_text: "Datele copilului au fost sterse cu succes!",
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="initializare";
		var data={
			oper: this.oper,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	updateInputValue(evt) {
		let tmp=this.state.data;
		if(evt.target.name==="nume") {
			tmp["nume_prenume"]=evt.target.value+" "+this.state.data.prenume;
		}
		if(evt.target.name==="prenume") {
			tmp["nume_prenume"]=this.state.data.nume+" "+evt.target.value;
		}
		if(evt.target.name==="cn_serie") {
			tmp["cn"]=evt.target.value+" "+this.state.data.cn_numar;
		}
		if(evt.target.name==="cn_numar") {
			tmp["cn"]=this.state.data.cn_serie+" "+evt.target.value;
		}
		if(evt.target.name==="ci_serie") {
			tmp["ci"]=evt.target.value+" "+this.state.data.ci_numar;
		}
		if(evt.target.name==="ci_numar") {
			tmp["ci"]=this.state.data.ci_serie+" "+evt.target.value;
		}
		if(evt.target.name==="handicap") {
			if((evt.target.value===1)||(evt.target.value==="1")) {
				tmp["handicap_nume"]="DA";
			}else{
				tmp["handicap_nume"]="NU";				
				tmp["grad_handicap"]="-";				
			}
			tmp.data_certificat_handicap=this.data_slash;
			tmp.data_start_recuperare=this.data_slash;
		}
		if(evt.target.name==="tip_recuperare") {
			if(evt.target.value==="-") {
				tmp["id_centru_recuperare"]=0;
				tmp["id_familie_recuperare"]=0;
			}
			if(evt.target.value==="RECUPERARE IN FAMILIE") {
				tmp["id_centru_recuperare"]=0;
			}
			if(evt.target.value==="RECUPERARE IN CENTRU") {
				tmp["id_familie_recuperare"]=0;
			}
		}
		tmp[evt.target.name]=evt.target.value;
		this.setState({
			data: tmp,
		});
	}

	updateInputValueCautare(evt) {
		let tmp=this.state.data_cautare;
		tmp[evt.target.name]=evt.target.value;
		this.setState({
			data_cautare: tmp,
		});
	}

	updateInputValueCautare_autocomplete(evt,value) {
		let tmp=this.state.data_cautare;
		if(value!==null) {
			tmp[evt.target.name]=evt.target.value;
		}else{
			tmp[evt.target.name]="";
		}
		this.setState({
			data_cautare: tmp,
		})
	}

    salveaza_date(tip) {
		if(this.state.data.cod_intern==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul cod intern copil!",				
			});
			return false;
		}
		if(this.state.data.nume==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul nume copil!",				
			});
			return false;
		}
		if(this.state.data.prenume==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul prenume copil!",				
			});
			return false;
		}
		if((this.state.data.handicap===1)||(this.state.data.handicap==="1")) {
			if(this.state.data.grad_handicap==="-") {
				this.setState({
					message_open: true,
					message_text: "Completati gardul de handicap!",				
				});
				return false;
			}
		}
		if((this.state.data.recuperare===1)||(this.state.data.recuperare==="1")) {
			if(this.state.data.tip_recuperare==="-") {
				this.setState({
					message_open: true,
					message_text: "Selectati tipul de recuperare!",
				});
				return false;
			}
		}
		if((this.state.data.tip_recuperare==="RECUPERARE IN FAMILIE")&&(this.state.data.id_familie_recuperare===0)) {
			this.setState({
				message_open: true,
				message_text: "Selectati familia de recuperare!",
			});
			return false;
		}
		if((this.state.data.tip_recuperare==="RECUPERARE IN CENTRU")&&(this.state.data.id_centru_recuperare===0)) {
			this.setState({
				message_open: true,
				message_text: "Selectati centrul de recuperare!",
			});
			return false;
		}
		if(tip==="adaugare") {
			this.oper="salveaza_adaugare";
		}else{
			this.oper="salveaza_modificare";
		}
		var data={
			oper: this.oper,
			data: this.state.data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	selectare(evt) {
		this.modif_el=clone_object(evt.value);
		if(this.modif_el.data_certificat_handicap==="00/00/0000") {
			this.modif_el.data_certificat_handicap=this.data_slash;
		}
		if(this.modif_el.data_start_recuperare==="00/00/0000") {
			this.modif_el.data_start_recuperare=this.data_slash;
		}
		this.setState({
			data: this.modif_el,
			selected_el: evt.value,
			dupa_modificare: 0,
		});
	}

	sterge_date() {
		if(window.confirm("Sigur doriti stergerea?")) {
			this.oper="salveaza_stergere";
			var data={
				oper: this.oper,
				data: this.state.data,
			};
			fetch_url_post(this.url,data,this.success_handler,this.fail_handler);		
		}
	}

	reseteaza(tip) {
		if(tip==="all") {
			this.setState({
				selected_el: undefined,
				data: clone_object(this.reset_data),
				export_excel: 0,
				data_cautare: clone_object(this.reset_data_cautare),
				lista_copii: [],
				anchorEl_operatiuni: null,
			});
		}else{
			this.setState({
				selected_el: undefined,
				data: clone_object(this.reset_data),
				export_excel: 0,
				data_cautare: clone_object(this.reset_data_cautare),
				anchorEl_operatiuni: null,
			});
		}
	}

    cautare_copil() {
		let ok_cautare=0;
		if(this.state.data_cautare.cautare_cod_intern!=="") {
			ok_cautare++;
		}
		if(this.state.data_cautare.cautare_nume!=="") {
			ok_cautare++;
		}
		if(this.state.data_cautare.cautare_prenume!=="") {
			ok_cautare++;
		}
		if(this.state.data_cautare.cautare_handicap!=="") {
			ok_cautare++;
		}
		if(this.state.data_cautare.cautare_grad_handicap!=="") {
			ok_cautare++;
		}
		if(this.state.data_cautare.cautare_id_centru!=="") {
			ok_cautare++;
		}
		if(this.state.data_cautare.cautare_id_asistent!=="") {
			ok_cautare++;
		}
		if(this.state.data_cautare.cautare_plasament_familial!=="") {
			ok_cautare++;
		}
		if(ok_cautare===0) {
			this.setState({
				message_open: true,
				message_text: "Completati cel putin un criteriu de cautare!",				
			});
			return false;
		}
		this.oper="cautare";
		var data={
			oper: this.oper,
			data: this.state.data_cautare,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	reseteaza_date_cautare() {
		this.setState({
			data_cautare: clone_object(this.reset_data_cautare),
			selected_el: undefined,
			data: clone_object(this.reset_data),
			export_excel: 0,
			lista_copii: [],
		});
	}

	familie() {
		this.setState({
			dialog_familie_open: true,
		})
	}

	comentarii() {
		this.setState({
			dialog_comentarii_open: true,
		});
	}

	fisiere() {
		this.setState({
			dialog_fisiere_open: true,
		});
	}

	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	forma_operatiuni() {
		return(
			<div style={{"padding":"30px"}}>
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<TextField
							required
							variant="outlined"
							margin="dense"
							name="cod_intern"
							label="Cod intern"
							fullWidth
							autoComplete="cod_intern"
							value={this.state.data.cod_intern}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							required
							variant="outlined"
							margin="dense"
							name="nume"
							label="Nume"
							fullWidth
							autoComplete="nume"
							value={this.state.data.nume}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							required
							variant="outlined"
							margin="dense"
							name="prenume"
							label="Prenume"
							fullWidth
							autoComplete="prenume"
							value={this.state.data.prenume}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="handicap"
							select
							label="Handicap"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.handicap}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.boolean_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="grad_handicap"
							select
							label="Grad"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.grad_handicap}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.grad_handicap_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cn_serie"
							label="Serie CN"
							fullWidth
							autoComplete="cn_serie"
							value={this.state.data.cn_serie}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cn_numar"
							label="Numar CN"
							fullWidth
							autoComplete="cn_numar"
							value={this.state.data.cn_numar}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="ci_serie"
							label="Serie CI"
							fullWidth
							autoComplete="ci_serie"
							value={this.state.data.ci_serie}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="ci_numar"
							label="Numar CI"
							fullWidth
							autoComplete="ci_numar"
							value={this.state.data.ci_numar}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="locul_nasterii"
							label="Locul nasterii"
							fullWidth
							autoComplete="locul_nasterii"
							value={this.state.data.locul_nasterii}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="nr_certificat_handicap"
							label="Nr certif."
							fullWidth
							autoComplete="nr_certificat_handicap"
							value={this.state.data.nr_certificat_handicap}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="data_certificat_handicap"
							label="Data certif."
							fullWidth
							autoComplete="data_certificat_handicap"
							value={((this.state.data.handicap===1)||(this.state.data.handicap==="1")?this.state.data.data_certificat_handicap:"")}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="valabilitate_certificat_handicap"
							label="Valab. certif."
							fullWidth
							autoComplete="valabilitate_certificat_handicap"
							value={this.state.data.valabilitate_certificat_handicap}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="tip_certificat_handicap"
							select
							label="Tip certif."
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.tip_certificat_handicap}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.tip_certificat_handicap_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="nivel_handicap_1"
							label="Nivel handicap 1"
							fullWidth
							autoComplete="nivel_handicap_1"
							value={this.state.data.nivel_handicap_1}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="nivel_handicap_2"
							label="Nivel handicap 2"
							fullWidth
							autoComplete="nivel_handicap_2"
							value={this.state.data.nivel_handicap_2}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="nivel_handicap_3"
							label="Nivel handicap 3"
							fullWidth
							autoComplete="nivel_handicap_3"
							value={this.state.data.nivel_handicap_3}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="invatamant_de_masa"
							label="Invatamant de masa"
							fullWidth
							autoComplete="invatamant_de_masa"
							value={this.state.data.invatamant_de_masa}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="invatamant_special"
							label="Invatamant special"
							fullWidth
							autoComplete="invatamant_special"
							value={this.state.data.invatamant_special}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="ajutor_stat"
							select
							label="Ajutor stat"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.ajutor_stat}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.ajutor_stat_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="recuperare"
							select
							label="Certificat activ"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.recuperare}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.boolean_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="face_recuperare"
							select
							label="Face recuperare"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.face_recuperare}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.boolean_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="data_start_recuperare"
							label="Data recup."
							fullWidth
							autoComplete="data_start_recuperare"
							value={((this.state.data.handicap===1)||(this.state.data.handicap==="1")?this.state.data.data_start_recuperare:"")}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							required
							variant="outlined"
							margin="dense"
							name="nr_dispozitie_recuperare"
							label="Nr. dispozitie recuperare"
							fullWidth
							value={this.state.data.nr_dispozitie_recuperare}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="tip_recuperare"
							select
							label="Locatie recup."
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.tip_recuperare}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.tip_recuperare_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							required
							variant="outlined"
							margin="dense"
							name="data_incetare_recuperare"
							label="Data stop recup."
							fullWidth
							value={this.state.data.data_incetare_recuperare}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					{!!(this.state.data.tip_recuperare==="FAMILIE") &&
						<Grid item xs={3}>
							<TextField
								variant="outlined"
								margin="dense"
								name="id_familie_recuperare"
								select
								label="Familie recuperare"
								fullWidth
								SelectProps={{
									native: true,
								}}
								value={this.state.data.id_familie_recuperare}
								onChange={evt => this.updateInputValue(evt)}
							>
								<option/>
								{this.state.lista_familii_recuperare.map(option => (
									<option key={option.id} value={option.id}>
										{option.nume}
									</option>
								))}
							</TextField>
						</Grid>
					}
					{!!(this.state.data.tip_recuperare==="REZIDENTIAL") &&
						<Grid item xs={3}>
							<TextField
								variant="outlined"
								margin="dense"
								name="id_centru_recuperare"
								select
								label="Centru recuperare"
								fullWidth
								SelectProps={{
									native: true,
								}}
								value={this.state.data.id_centru_recuperare}
								onChange={evt => this.updateInputValue(evt)}
							>
								<option/>
								{this.state.lista_centre_recuperare.map(option => (
									<option key={option.id} value={option.id}>
										{option.nume}
									</option>
								))}
							</TextField>
						</Grid>
					}
					{!!(this.state.data.tip_recuperare==="AMP") &&
						<Grid item xs={3}>
							<TextField
								variant="outlined"
								margin="dense"
								name="id_asistent_recuperare"
								select
								label="Asistent maternal"
								fullWidth
								SelectProps={{
									native: true,
								}}
								value={this.state.data.id_asistent_recuperare}
								onChange={evt => this.updateInputValue(evt)}
							>
								<option/>
								{this.state.lista_asistenti_recuperare.map(option => (
									<option key={option.id} value={option.id}>
										{option.nume}
									</option>
								))}
							</TextField>
						</Grid>
					}
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							margin="dense"
							name="observatii"
							label="Observatii"
							fullWidth
							multiline
							rows={3}
							autoComplete="observatii"
							value={this.state.data.observatii}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
				</Grid>
				{this.forma_operatiuni_butoane()}				
			</div>
		);
	}

	forma_operatiuni_butoane() {
		if(this.state.selected_el!==undefined) {
			return(		
				<div style={{"textAlign":"center","paddingLeft":"126px"}}>
					<Button onClick={(evt)=>{this.familie()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"9px"}}>
						<PeopleIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Familie
					</Button>
					{this.props.sess.rol>10&&
						<React.Fragment>
							<Button onClick={(evt)=>{this.salveaza_date("modificare")}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"9px"}}>
								<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
								Modificare
							</Button>
						</React.Fragment>
					}
					<Button onClick={(evt)=>{this.reseteaza("date")}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","float":"right"}}>
						<CloseIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Inchide fereastra
					</Button>
				</div>
			);
		}else{
			return(
				<div style={{"textAlign":"center"}}>
					{this.props.sess.rol>10&&
						<Button onClick={(ev)=>{this.salveaza_date("adaugare")}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px"}}>
							<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Adaugare
						</Button>
					}					
					<Button onClick={(evt)=>{this.reseteaza("date")}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","float":"right"}}>
						<CloseIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Inchide fereastra
					</Button>
				</div>
			);
		}		
	}

	forma_cautare() {
		return(
			<React.Fragment>
				<Grid container spacing={2}>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_cod_intern"
							id="cautare_cod_intern"
							label="Cod intern"
							fullWidth
							autoComplete="cautare_cod_intern"
							value={this.state.data_cautare.cautare_cod_intern}
							onChange={evt => this.updateInputValueCautare(evt)}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_nume"
							id="cautare_nume"
							label="Nume"
							fullWidth
							autoComplete="cautare_nume"
							value={this.state.data_cautare.cautare_nume}
							onChange={evt => this.updateInputValueCautare(evt)}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_prenume"
							id="cautare_prenume"
							label="Prenume"
							fullWidth
							autoComplete="cautare_prenume"
							value={this.state.data_cautare.cautare_prenume}
							onChange={evt => this.updateInputValueCautare(evt)}
						/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_handicap"
							id="cautare_handicap"
							select
							label="Handicap"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_handicap}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.boolean_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_grad_handicap"
							id="cautare_grad_handicap"
							select
							label="Grad handicap"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_grad_handicap}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.grad_handicap_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={3}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_nume_mama"
							id="cautare_nume_mama"
							select
							label="Mama"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_nume_mama}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.lista_mama.map(option => (
								<option key={option.id} value={option.nume_mama}>
									{option.nume_mama}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={3}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_nume_tata"
							id="cautare_nume_tata"
							select
							label="Tata"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_nume_tata}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.lista_tata.map(option => (
								<option key={option.id} value={option.nume_tata}>
									{option.nume_tata}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={3}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_id_frati"
							id="cautare_id_frati"
							select
							label="Frati/surori"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_id_frati}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.lista_frati.map(option => (
								<option key={option.id} value={option.id}>
									{option.nume_frate}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={3}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_id_ruda"
							id="cautare_id_ruda"
							select
							label="Rude/Familie"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_id_ruda}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.lista_rude.map(option => (
								<option key={option.id} value={option.id}>
									{option.nume_ruda}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						{this.forma_cautare_butoane()}
					</Grid>					
				</Grid>
			</React.Fragment>
		);
	}

	dialog_familie() {
		let titlu="Date familie ";
		if(this.state.selected_el) {
			titlu+=this.state.selected_el.nume_prenume+" ("+this.state.selected_el.cod_intern+")";
		}
		return(
			<Dialog
				open={this.state.dialog_familie_open}
				onClose={(ev)=>{this.setState({dialog_familie_open: false})}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
				maxWidth={false}
		  	>
				<DialogTitle id="alert-dialog-title">
					{titlu}
					<Fab color="secondary" size="small" style={{"float":"right"}} onClick={(ev)=>{this.setState({dialog_familie_open: false})}}>
        				<Icon>close_icon</Icon>
					</Fab>
				</DialogTitle>
				<DialogContent style={{"paddingBottom":"33px"}}>
					<Familie sess={this.props.sess} copil={this.state.selected_el}/>
        		</DialogContent>
			</Dialog>
		);
	}

	forma_cautare_butoane() {
		if(this.state.export_excel===1) {
			return(
				<React.Fragment>
					<Button onClick={(evt)=>{this.cautare_copil()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"9px"}}>
						<SearchIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Cautare
					</Button>
					<Button onClick={(evt)=>{this.reseteaza_date_cautare()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px"}}>
						<RefreshIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Reseteaza
					</Button>
					<RaportExcel nume={"copii"} data={this.state.lista_copii}/>
				</React.Fragment>
			);
		}else{
			return(
				<React.Fragment>
					<Button onClick={(evt)=>{this.cautare_copil()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"9px"}}>
						<SearchIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Cautare
					</Button>
					<Button onClick={(evt)=>{this.reseteaza_date_cautare()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px"}}>
						<RefreshIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Reseteaza
					</Button>
				</React.Fragment>
			);
		}
	}

	render_operatiuni_menu() {
		return (
			<Menu
				anchorEl={this.state.anchorEl_operatiuni}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				keepMounted={true}
				open={Boolean(this.state.anchorEl_operatiuni)}
				onClose={(ev)=>this.setState({anchorEl_operatiuni: null})}
			>
				{this.forma_operatiuni()}
			</Menu>
		);
	};

	dialog_comentarii() {
		let titlu="Comentarii ";
		if(this.state.selected_el) {
			titlu+="pentru "+this.state.selected_el.nume_prenume;
		}
		return(
			<Dialog
				open={this.state.dialog_comentarii_open}
				onClose={(ev)=>{this.setState({dialog_comentarii_open: false})}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
				maxWidth={false}
		  	>
				<DialogTitle id="alert-dialog-title">
					{titlu}
					<Fab color="secondary" size="small" style={{"float":"right"}} onClick={(ev)=>{this.setState({dialog_comentarii_open: false})}}>
        				<Icon>close_icon</Icon>
					</Fab>
				</DialogTitle>
				<DialogContent style={{"paddingBottom":"33px"}}>
					<Comentarii sess={this.props.sess} copil={this.state.selected_el}/>
        		</DialogContent>
			</Dialog>
		);
	}

	dialog_fisiere() {
		let titlu="Fisiere incarcate ";
		if(this.state.selected_el) {
			titlu+="pentru "+this.state.selected_el.nume_prenume;
		}
		return(
			<Dialog
				open={this.state.dialog_fisiere_open}
				onClose={(ev)=>{this.setState({dialog_fisiere_open: false})}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				disableEscapeKeyDown={true}
				maxWidth={false}
		  	>
				<DialogTitle id="alert-dialog-title">
					{titlu}
					<Fab color="secondary" size="small" style={{"float":"right"}} onClick={(ev)=>{this.setState({dialog_fisiere_open: false})}}>
        				<Icon>close_icon</Icon>
					</Fab>
				</DialogTitle>
				<DialogContent style={{"paddingBottom":"33px"}}>
					<FisiereCopii sess={this.props.sess} copil={this.state.selected_el}/>
        		</DialogContent>
			</Dialog>
		);
	}

	render() {
		if(this.state.is_loaded===1) {
			let titlu_rezultate="Rezultate cautare ("+this.state.lista_copii.length+" rezultate)";
			return(
				<div className="fix_height">
					<Fieldset legend="Criterii cautare copii" name="tabel_items" id="tabel_items" className="text_center" style={{"padding":"0px","margin":"0px","marginTop":"-12px","marginBottom":"12px"}}>
						{this.forma_cautare()}
					</Fieldset>
					<Fieldset legend={titlu_rezultate} name="tabel_items" id="tabel_items" className="text_center" style={{"padding":"0px","margin":"0px","flex":"1 1 auto","paddingTop":"12px"}}>
						<Grid container spacing={1} style={{"marginBottom":"3px","marginTop":"-12px"}}>
							<Grid item xs={2}>
								&nbsp;
							</Grid>
							{!!(!(this.state.selected_el)) &&
								<React.Fragment>
									<Grid item xs={4}>
										&nbsp;
									</Grid>
									<Grid item xs={4}>
										&nbsp;
									</Grid>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.setState({anchorEl_operatiuni: evt.currentTarget})}} style={{}} color="primary" variant="contained" fullWidth>
											<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Adauga copil
										</Button>
									</Grid>
								</React.Fragment>
							}
							{!!(this.state.selected_el) &&
								<React.Fragment>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.comentarii()}} color="primary" variant="contained" fullWidth>
											<ReorderIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Comentarii
										</Button>
									</Grid>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.fisiere()}} color="primary" variant="contained" fullWidth>
											<BorderAllIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Fisiere
										</Button>
									</Grid>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.setState({anchorEl_operatiuni: evt.currentTarget})}} color="primary" variant="contained" fullWidth>
											<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Modificare copil
										</Button>
									</Grid>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.sterge_date()}} color="secondary" variant="contained" fullWidth>
											<DeleteOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Sterge copil
										</Button>
									</Grid>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.setState({anchorEl_operatiuni: evt.currentTarget})}} style={{}} color="primary" variant="contained" fullWidth>
											<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Adauga copil
										</Button>
									</Grid>
								</React.Fragment>
							}
						</Grid>
						<DataTable ref={(el) => this.dt = el} 
							value={this.state.lista_copii}
							scrollable={true}
							paginator={true}
							rows={12}
							selectionMode="single"
							selection={this.state.selected_el}
							onSelectionChange={(e)=>{this.selectare(e)}}
							sortField="nume_prenume"
							sortOrder={1}
						>
							<Column field="cod_intern" header="Cod intern" style={{"width":"9%"}} filter={true} filterMatchMode="contains"/>
							<Column field="nume_prenume" header="Nume si prenume" style={{"width":"60%"}} filter={true} filterMatchMode="contains"/>
							<Column field="cn" header="C.N." style={{"width":"3%"}} filterMatchMode="contains"/>
							<Column field="ci" header="C.I." style={{"width":"3%"}} filterMatchMode="contains"/>
							<Column field="handicap_nume" header="Handicap" style={{"width":"6%"}} filter={true} filterMatchMode="contains"/>
							<Column field="grad_handicap" header="Grad" style={{"width":"9%"}} filter={true} filterMatchMode="contains"/>
							<Column field="data_introducerii" header="Data creare" style={{"width":"9%"}}/>							
						</DataTable>
					</Fieldset>
					{this.render_operatiuni_menu()}
					{this.message()}
					{this.dialog_familie()}
					{this.dialog_comentarii()}
					{this.dialog_fisiere()}
				</div>
			)			
		}else{
			return(
				<LinearProgress/>
			);
		}
	}
}

export default Copii;
