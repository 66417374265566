import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Fieldset} from 'primereact/fieldset';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import LinearProgress from '@material-ui/core/LinearProgress';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,DatePicker} from 'material-ui-pickers';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Menu from '@material-ui/core/Menu';
import Badge from '@mui/material/Badge';
import RaportExcel from './RaportExcel';
import {fetch_url_post,add_to_array,remove_from_array,find_index_in_array,replace_element_in_array,clone_object, find_element_in_array} from "../../Sources/js/app.js";

class Masuri extends React.Component {
	constructor(props) {
		super(props);
		this.data=new Date().toISOString().slice(0,10).replace('T', ' ');
		let tmp_data=this.data.split("-");
		this.data=tmp_data[2]+"-"+tmp_data[1]+"-"+tmp_data[0];
		this.data_cautare=tmp_data[0]+"-"+tmp_data[1]+"-"+tmp_data[2];
		this.data_slash=tmp_data[2]+"/"+tmp_data[1]+"/"+tmp_data[0];		
//		console.log(this.data);
		this.reset_data={
			id: 0,
			id_copil: 0,
			masura: "",
			tip: "",
			fel: "",
			id_centru: 0,
			id_asistent_maternal: 0,
			id_ruda_plasament: 0,
			id_familie_plasament: 0,
			decident: "",
			judet: "",
			nr_act: "",
			data_act: this.data_slash,
			data_introducerii: this.data_slash,
			id_asistent_social: 0,
			activa: 1,
			id_familie_adoptiva: 0,
			motiv: "",
			data_inactivarii: "",
			observatii_inactivare: "",
		}
		this.reset_data_cautare={
			cautare_id_copil: 0,
			cautare_masura: "",
			cautare_tip: "",
			cautare_fel: "",
			cautare_varsta_min: "",
			cautare_varsta_max: "",
			cautare_data_min: "1994-01-01",
			cautare_data_max: "2099-12-31",
			cautare_handicap: "",
			cautare_istoric: 0,
			cautare_id_centru: 0,
			cautare_id_asistent_maternal: 0,
			cautare_id_ruda_plasament: 0,
			cautare_id_familie_plasament: 0,
			cautare_id_familie_adoptiva: 0,
			cautare_localitate: "",
		}
		this.state={
            is_loaded: 0,
			lista_masuri: [],
			message_open: false,
			message_text: "",
			selected_el: undefined,
			data:clone_object(this.reset_data),
			data_cautare:clone_object(this.reset_data_cautare),
			dupa_modificare: 0,
			lista_asistenti_maternali: [],
			lista_centre: [],
			lista_familii_plasament: [],			
			lista_rude_plasament: [],
			lista_asistenti_sociali: [],
			lista_copii: [],
			lista_familii_adoptive: [],
			lista_tip_masuri: [
				{
					label: "PLASAMENT",
					value: "PLASAMENT",
				},
				{
					label: "TRANSFER",
					value: "TRANSFER",
				},
				{
					label: "PLASAMENT IN REGIM DE URGENTA",
					value: "PLASAMENT IN REGIM DE URGENTA",
				},				
				{
					label: "DPA",
					value: "DPA",
				},
				{
					label: "PROGRAM RECUPERARE",
					value: "PROGRAM RECUPERARE",
				},
				{
					label: "PREADOPTIE",
					value: "PREADOPTIE",
				},
				{
					label: "ADOPTIE",
					value: "ADOPTIE",
				},
				{
					label: "INTERNARE",
					value: "INTERNARE",
				},
				{
					label: "MENTINERE",
					value: "MENTINERE",
				},				
				{
					label: "INCETARE",
					value: "INCETARE",
				},
			],
			lista_tip: [
				{
					label: "AMP",
					value: "AMP",
				},
				{
					label: "FAMILIAL",
					value: "FAMILIAL",
				},
				{
					label: "REZIDENTIAL",
					value: "REZIDENTIAL",
				},				
				{
					label: "FAMILIE",
					value: "FAMILIE",
				},
				{
					label: "IMPLINIREA VARSTEI DE 18/26 ANI CF. LEGII",
					value: "IMPLINIREA VARSTEI DE 18/26 ANI CF. LEGII",
				},				
				{
					label: "ALTE CAUZE DECLINARE COMPETENTA",
					value: "ALTE CAUZE DECLINARE COMPETENTA",
				},
				{
					label: "ALTE CAUZE TRANSFER",
					value: "ALTE CAUZE TRANSFER",
				},
				{
					label: "ALTE CAUZE DECES",
					value: "ALTE CAUZE DECES",
				},				
				{
					label: "ALTE CAUZE IVA/ADOPTIE",
					value: "ALTE CAUZE IVA/ADOPTIE",
				},
				{
					label: "ALTE CAUZE - ETC",
					value: "ALTE CAUZE - ETC",
				},				
				{
					label: "CONTINUARE STUDII",
					value: "CONTINUARE STUDII",
				},
			],
			lista_fel: [
				{
					label: "ASISTENT MATERNAL",
					value: "ASISTENT MATERNAL",
				},
				{
					label: "CENTRU PLASAMENT",
					value: "CENTRU PLASAMENT",
				},				
				{
					label: "CENTRU PLASAMENT FAMILIAL",
					value: "CENTRU PLASAMENT FAMILIAL",
				},
				{
					label: "CENTRU PLASAMENT HANDICAP",
					value: "CENTRU PLASAMENT HANDICAP",
				},
				{
					label: "CENTRU DE ZI",
					value: "CENTRU DE ZI",
				},
				{
					label: "FAMILIE PLASAMENT",
					value: "FAMILIE PLASAMENT",
				},
				{
					label: "FAMILIE NATURALA",
					value: "FAMILIE NATURALA",
				},
				{
					label: "RUDE GRAD IV",
					value: "RUDE GRAD IV",
				},
				{
					label: "FAMILIE ADOPTIVA",
					value: "FAMILIE ADOPTIVA",
				},
				{
					label: "CENTRU PREVENTIE",
					value: "CENTRU PREVENTIE",
				},
				{
					label: "SOCIETATE",
					value: "SOCIETATE",
				},
				{
					label: "CPMRU",
					value: "CPMRU",
				},
				{
					label: "SERVICII DE TIP FAMILIAL",
					value: "SERVICII DE TIP FAMILIAL",
				},
				{
					label: "SERVICII DE TIP REZIDENTIAL",
					value: "SERVICII DE TIP REZIDENTIAL",
				},
			],
			lista_decidenti: [
				{
					label: "CPC",
					value: "CPC",
				},
				{
					label: "DPC",
					value: "DPC",
				},				
				{
					label: "INSTANTA",
					value: "INSTANTA",
				},
			],
			boolean_list: [
				{
					label: "DA",
					value: 1,
				},
				{
					label: "NU",
					value: 0,
				},				
			],
			export_excel: 1,
			key_date_cautare: Math.random(),
			key_date_introducere: Math.random(),
			anchorEl_operatiuni: null,
			anchorEl_filtrare: null,
		};
		this.filter_options=createFilterOptions({
			matchFrom: 'start',
			stringify: option => option.nume,
		});
        this.oper="";
		this.modif_el={};
		this.ok_cautare=0;
		this.url="masuri.asp?session-id="+this.props.sess.sessid;
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.updateInputValue=this.updateInputValue.bind(this);
		this.message=this.message.bind(this);	
		this.close_message=this.close_message.bind(this);
		this.selectare=this.selectare.bind(this);
		this.forma_operatiuni=this.forma_operatiuni.bind(this);
		this.salveaza_date=this.salveaza_date.bind(this);
		this.sterge_date=this.sterge_date.bind(this);
		this.reseteaza=this.reseteaza.bind(this);
		this.forma_operatiuni_butoane=this.forma_operatiuni_butoane.bind(this);
		this.forma_cautare=this.forma_cautare.bind(this);
		this.updateInputValueCautare=this.updateInputValueCautare.bind(this);
		this.cautare_masura=this.cautare_masura.bind(this);
		this.reseteaza_date_cautare=this.reseteaza_date_cautare.bind(this);
		this.updateInputValueCautare_autocomplete=this.updateInputValueCautare_autocomplete.bind(this);
		this.updateInputValue_autocomplete=this.updateInputValue_autocomplete.bind(this);
		this.updateInputValueCautare_data=this.updateInputValueCautare_data.bind(this);
		this.forma_cautare_butoane=this.forma_cautare_butoane.bind(this);
		this.render_operatiuni_menu=this.render_operatiuni_menu.bind(this);
		this.render_filtrare_menu=this.render_filtrare_menu.bind(this);
	}
		
	success_handler(result) {
		if(this.oper==="initializare") {
			this.oper="";
			if(result.ok===1) {
				if(result.data.lista_masuri===null) {
					result.data.lista_masuri=[];
				}
				if(result.data.lista_asistenti_maternali===null) {
					result.data.lista_asistenti_maternali=[];
				}
				if(result.data.lista_centre===null) {
					result.data.lista_centre=[];
				}
				if(result.data.lista_familii_plasament===null) {
					result.data.lista_familii_plasament=[];
				}
				if(result.data.lista_rude_plasament===null) {
					result.data.lista_rude_plasament=[];
				}
				if(result.data.lista_asistenti_sociali===null) {
					result.data.lista_asistenti_sociali=[];
				}
				if(result.data.lista_copii===null) {
					result.data.lista_copii=[];
				}
				if(result.data.lista_familii_adoptive===null) {
					result.data.lista_familii_adoptive=[];
				}
				this.setState({
					is_loaded: 1,
					lista_masuri: result.data.lista_masuri,
					lista_asistenti_maternali: result.data.lista_asistenti_maternali,
					lista_centre: result.data.lista_centre,
					lista_familii_plasament: result.data.lista_familii_plasament,
					lista_rude_plasament: result.data.lista_rude_plasament,
					lista_asistenti_sociali: result.data.lista_asistenti_sociali,
					lista_copii: result.data.lista_copii,					
					lista_familii_adoptive: result.data.lista_familii_adoptive,
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="cautare") {
			this.oper="";
			if(result.ok===1) {
				let export_excel=0;
				if(result.data.lista_masuri.length>0) {
					export_excel=1;
				}
				this.setState({
					is_loaded: 1,
					lista_masuri: result.data.lista_masuri,
					export_excel: export_excel,
					anchorEl_filtrare: null,
					selected_el: (this.state.selected_el?result.data.lista_masuri[find_index_in_array(result.data.lista_masuri,this.state.selected_el)]:null),
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});				
			}
		}
		if(this.oper==="salveaza_adaugare") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					data: clone_object(this.reset_data),
					key_date_cautare: Math.random(),
					key_date_introducere: Math.random(),
					message_open: true,
					message_text: "Masura a fost adaugata cu succes!",
					anchorEl_operatiuni: null,
				});
				this.cautare_masura();
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
					anchorEl_operatiuni: null,
				});
			}
		}
		if(this.oper==="salveaza_modificare") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
//					data: clone_object(this.reset_data),
					dupa_modificare: 1,
					key_date_cautare: Math.random(),
					key_date_introducere: Math.random(),
					message_open: true,
					message_text: "Masura a fost modificata cu succes!",
					anchorEl_operatiuni: null,
				});
				this.cautare_masura();
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
					anchorEl_operatiuni: null,
				});
			}
		}
		if(this.oper==="salveaza_stergere") {
			this.oper="";
			if(result.ok===1) {
				this.setState({
					is_loaded: 1,
					data: clone_object(this.reset_data),
					lista_masuri: remove_from_array(this.state.lista_masuri,this.state.selected_el),
					selected_el: undefined,
					message_open: true,
					message_text: "Masura a fost stearsa cu succes!",
				});
			}else{
				this.setState({
					is_loaded: 1,
					message_open: true,
					message_text: result.error,
				});
			}
		}
	}

	fail_handler() {
		this.setState({
			result: null,
			is_loaded: 1,
			message_open: true,
			message_text: "A intervenit o eroare de comunicatie! Va rugam sa incercati mai tarziu!",
		});
	}

    componentDidMount() {
		this.oper="initializare";
		var data={
			oper: this.oper,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	updateInputValue(evt) {
		let tmp=this.state.data;
		tmp[evt.target.name]=evt.target.value;
		this.setState({
			data: tmp,
		});
	}

	updateInputValue_autocomplete(evt,value,name) {
		let tmp=this.state.data;
		if(value!==null) {
			tmp[name]=value.id;
		}else{
			tmp[name]="";
		}
		this.setState({
			data: tmp,
		})
	}

	updateInputValueCautare(evt) {
		let tmp=this.state.data_cautare;
		tmp[evt.target.name]=evt.target.value;
		if(evt.target.name==="cautare_fel") {
			//trebuie resetate toate autocomplete-urile subsecvente
			tmp.cautare_id_asistent_maternal=0;
			tmp.cautare_id_centru=0;
			tmp.cautare_id_copil=0;
			tmp.cautare_id_familie_adoptiva=0;
			tmp.cautare_id_familie_plasament=0;
			tmp.cautare_id_ruda_plasament=0;
		}
		this.setState({
			data_cautare: tmp,
		});
	}

	updateInputValueCautare_autocomplete(evt,value,name) {
		let tmp=this.state.data_cautare;
		if(value!==null) {
			tmp[name]=value.id;
		}else{
			tmp[name]="";
		}
		this.setState({
			data_cautare: tmp,
		})
	}

	updateInputValueCautare_data(date,name) {
		let year=date.getFullYear();
		let month=date.getMonth()+1;
		let day=date.getDate();
		if(month<10) {
			month="0"+month;
		}
		if(day<10) {
			day="0"+day;
		}
//		console.log(date);
//		console.log(year+"-"+month+"-"+day);
		let tmp=this.state.data_cautare;
		tmp[name]=year+"-"+month+"-"+day;
		this.setState({
			data_cautare: tmp,
		});
	}

    salveaza_date(tip) {
		if(this.state.data.id_copil===0) {
			this.setState({
				message_open: true,
				message_text: "Completati campul copil!",				
			});
			return false;
		}
		if(this.state.data.masura==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul masura!",				
			});
			return false;
		}
		if(this.state.data.tip==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul tip!",				
			});
			return false;
		}
		if(this.state.data.fel==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul fel!",				
			});
			return false;
		}
		if((this.state.data.fel==="CENTRU PLASAMENT")||(this.state.data.fel==="CENTRU PLASAMENT HANDICAP")||(this.state.data.fel==="CENTRU PLASAMENT FAMILIAL")) {
			if(this.state.data.id_centru===0) {
				this.setState({
					message_open: true,
					message_text: "Completati campul centru plasament!",				
				});
				return false;
			}
		}
		if(this.state.data.fel==="ASISTENT MATERNAL") {
			if(this.state.data.id_asistent_maternal===0) {
				this.setState({
					message_open: true,
					message_text: "Completati campul sistent maternal!",				
				});
				return false;
			}
		}
		if(this.state.data.fel==="RUDE GRAD IV") {
			if(this.state.data.id_ruda_plasament===0) {
				this.setState({
					message_open: true,
					message_text: "Completati campul ruda plasament!",				
				});
				return false;
			}
		}
		if(this.state.data.fel==="FAMILIE PLASAMENT") {
			if(this.state.data.id_familie_plasament===0) {
				this.setState({
					message_open: true,
					message_text: "Completati campul familie plasament!",				
				});
				return false;
			}
		}
		if(this.state.data.fel==="FAMILIE ADOPTIVA") {
			if(this.state.data.id_familie_adoptiva===0) {
				this.setState({
					message_open: true,
					message_text: "Completati campul familie adoptiva!",				
				});
				return false;
			}
		}
		if(this.state.data.decident==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul decident!",				
			});
			return false;
		}
		if(this.state.data.judet==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul judet!",				
			});
			return false;
		}
		if(this.state.data.nr_act==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul numar act!",				
			});
			return false;
		}
		if(this.state.data.data_act==="") {
			this.setState({
				message_open: true,
				message_text: "Completati campul data act!",				
			});
			return false;
		}
//		if(this.state.data.id_asistent_social===0) {
//			this.setState({
//				message_open: true,
//				message_text: "Completati campul asistent social!",				
//			});
//			return false;
//		}
		if(tip==="adaugare") {
			this.oper="salveaza_adaugare";
		}else{
			this.oper="salveaza_modificare";
		}
		var data={
			oper: this.oper,
			data: this.state.data,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
	}

	selectare(evt) {
		this.modif_el=clone_object(evt.value);
		this.setState({
			data: this.modif_el,
			selected_el: evt.value,
			dupa_modificare: 0,
			key_date_introducere: Math.random(),
		});
	}

	sterge_date() {
		if(window.confirm("Sigur doriti stergerea?\n\n\nOperatiunea eset ireversibila!")) {
			this.oper="salveaza_stergere";
			var data={
				oper: this.oper,
				data: this.state.data,
			};
			fetch_url_post(this.url,data,this.success_handler,this.fail_handler);		
		}
	}

	reseteaza(tip) {
		if(tip==="all") {
			this.setState({
				selected_el: undefined,
				data: clone_object(this.reset_data),
				key_date_introducere: Math.random(),
				data_cautare: clone_object(this.reset_data_cautare),
				lista_masuri: [],
				anchorEl_operatiuni: null,
			});
		}else{
			this.setState({
				selected_el: undefined,
				data: clone_object(this.reset_data),
				key_date_introducere: Math.random(),
				data_cautare: clone_object(this.reset_data_cautare),
				anchorEl_operatiuni: null,
			});			
		}
	}

    cautare_masura() {
		this.ok_cautare=0;
		if((this.state.data_cautare.cautare_id_copil!==0)&&(this.state.data_cautare.cautare_id_copil!=="0")) {
			console.log("1 "+this.ok_cautare+" "+this.state.data_cautare.cautare_id_copil);
			this.ok_cautare++;
		}
		if(this.state.data_cautare.cautare_masura!=="") {
			console.log("2 "+this.ok_cautare+" "+this.state.data_cautare.cautare_masura);
			this.ok_cautare++;
		}
		if(this.state.data_cautare.cautare_tip!=="") {
			console.log("3 "+this.ok_cautare+" "+this.state.data_cautare.cautare_tip);
			this.ok_cautare++;
		}
		if(this.state.data_cautare.cautare_fel!=="") {
			console.log("4 "+this.ok_cautare+" "+this.state.data_cautare.cautare_fel);
			this.ok_cautare++;
		}
		if((this.state.data_cautare.cautare_id_asistent_maternal!==0)&&(this.state.data_cautare.cautare_id_asistent_maternal!=="0")) {
			console.log("5 "+this.ok_cautare+" "+this.state.data_cautare.cautare_id_asistent_maternal);
			this.ok_cautare++;
		}
		if((this.state.data_cautare.cautare_id_centru!==0)&&(this.state.data_cautare.cautare_id_centru!=="0")) {
			console.log("6 "+this.ok_cautare+" "+this.state.data_cautare.cautare_id_centru);
			this.ok_cautare++;
		}
		if((this.state.data_cautare.cautare_id_familie_plasament!==0)&&(this.state.data_cautare.cautare_id_familie_plasament!=="0")) {
			console.log("7 "+this.ok_cautare+" "+this.state.data_cautare.cautare_id_familie_plasament);
			this.ok_cautare++;
		}
		if((this.state.data_cautare.cautare_id_ruda_plasament!==0)&&(this.state.data_cautare.cautare_id_ruda_plasament!=="0")) {
			console.log("8 "+this.ok_cautare+" "+this.state.data_cautare.cautare_id_ruda_plasament);
			this.ok_cautare++;
		}
//		if((this.state.data_cautare.cautare_id_asistent_social!==0)&&(this.state.data_cautare.cautare_id_asistent_social!=="0")) {
//			console.log("9 "+this.ok_cautare+" "+this.state.data_cautare.cautare_id_asistent_social);
//			this.ok_cautare++;
//		}
		if(this.state.data_cautare.cautare_varsta_min!=="") {
			console.log("10 "+this.ok_cautare+" "+this.state.data_cautare.cautare_varsta_min);
			this.ok_cautare++;
		}
		if(this.state.data_cautare.cautare_varsta_max!=="") {
			console.log("11 "+this.ok_cautare+" "+this.state.data_cautare.cautare_varsta_max);
			this.ok_cautare++;
		}
		if(this.state.data_cautare.data_min) {
			console.log("12 "+this.ok_cautare+" "+this.state.data_cautare.data_operatiunii_min);
			this.ok_cautare++;
		}
		if(this.state.data_cautare.data_max) {
			console.log("13 "+this.ok_cautare+" "+this.state.data_cautare.data_operatiunii_max);
			this.ok_cautare++;
		}
		if(this.state.data_cautare.cautare_localitate!=="") {
			console.log("14 "+this.ok_cautare+" "+this.state.data_cautare.cautare_localitate);
			this.ok_cautare++;
		}
		this.oper="cautare";
		var data={
			oper: this.oper,
			data: this.state.data_cautare,
		};
		fetch_url_post(this.url,data,this.success_handler,this.fail_handler);
		this.setState({
			is_loaded: 0,
		});
	}

	reseteaza_date_cautare(tip) {
		if(tip==="all") {
			this.setState({
				data_cautare: clone_object(this.reset_data_cautare),
				key_date_cautare: Math.random(),
				selected_el: undefined,
				data: clone_object(this.reset_data),
				export_excel: 0,
				lista_masuri: [],
			});
		}else{
			this.setState({
				data_cautare: clone_object(this.reset_data_cautare),
				key_date_cautare: Math.random(),
				selected_el: undefined,
				data: clone_object(this.reset_data),
				export_excel: 0,
			});
		}
	}

	filter_options() {
		return(
			createFilterOptions({
						matchFrom: 'start',
			})
		);
	}
	  
	message(content) {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={this.state.message_open}
				autoHideDuration={3000}
				onClose={this.close_message}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.message_text}</span>}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.close_message}
					>
					<CloseIcon />
					</IconButton>,
				]}
			/>
		);
	}

	close_message(event,reason) {
		if(reason==="clickaway") {
			return;
		}
		this.setState({
			message_open: false,
			message_text: "",
		});
	}

	forma_operatiuni() {
		let id_copil=(this.state.selected_el?this.state.selected_el.id_copil:0);
		return(
			<div style={{"padding":"30px"}}>
				<Grid container spacing={1}>
					<Grid item xs={4}>
						<Autocomplete
							key={"1_"+this.state.key_date_introducere}
							options={this.state.lista_copii}
      						getOptionLabel={option => option.nume}
							defaultValue={this.state.lista_copii[this.state.lista_copii.findIndex((copil=>copil.id===id_copil))]} 
							noOptionsText="Nu exista copii"
							onChange={(ev,value)=>{this.updateInputValue_autocomplete(ev,value,"id_copil")}}
							disableClearable
							disableListWrap
							autoComplete
							size="small"
							filterOptions={this.filter_options}
							name="id_copil"
							renderInput={params => (
								<TextField
									{...params}
									label="Copil"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							)}
    					/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							variant="outlined"
							margin="dense"
							name="masura"
							select
							label="Masura"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.masura}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.lista_tip_masuri.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="tip"
							select
							label="Tip"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.tip}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.lista_tip.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={3}>
						<TextField
							variant="outlined"
							margin="dense"
							name="fel"
							select
							label="Fel"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.fel}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.lista_fel.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							key={"2_"+this.state.key_date_introducere}
							options={this.state.lista_asistenti_maternali}
      						getOptionLabel={option => option.nume}
							noOptionsText="Nu exista persoane"
							onChange={(ev,value)=>{this.updateInputValue_autocomplete(ev,value,"id_asistent_maternal")}}
							disableClearable
							disableListWrap
							autoComplete
							size="small"
							filterOptions={this.filter_options}
							name="id_asistent_maternal"
							renderInput={params => (
								<TextField
									{...params}
									label="Asistent maternal"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							)}
    					/>
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							key={"3_"+this.state.key_date_introducere}
							options={this.state.lista_centre}
      						getOptionLabel={option => option.nume}
							noOptionsText="Nu exista centre"
							onChange={(ev,value)=>{this.updateInputValue_autocomplete(ev,value,"id_centru")}}
							disableClearable
							disableListWrap
							autoComplete
							size="small"
//							filterOptions={this.filter_options}
							name="id_centru"
							renderInput={params => (
								<TextField
									{...params}
									label="Centru"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							)}
    					/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							key={"4_"+this.state.key_date_introducere}
							options={this.state.lista_familii_plasament}
      						getOptionLabel={option => option.nume}
							noOptionsText="Nu exista persoane"
							onChange={(ev,value)=>{this.updateInputValue_autocomplete(ev,value,"id_familie_plasament")}}
							disableClearable
							disableListWrap
							autoComplete
							size="small"
							filterOptions={this.filter_options}
							name="id_familie_plasament"
							renderInput={params => (
								<TextField
									{...params}
									label="Familie plasament"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							)}
    					/>
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							key={"5_"+this.state.key_date_introducere}
							options={this.state.lista_rude_plasament}
      						getOptionLabel={option => option.nume}
							noOptionsText="Nu exista persoane"
							onChange={(ev,value)=>{this.updateInputValue_autocomplete(ev,value,"id_ruda_plasament")}}
							disableClearable
							disableListWrap
							autoComplete
							size="small"
							filterOptions={this.filter_options}
							name="id_ruda_plasament"
							renderInput={params => (
								<TextField
									{...params}
									label="Ruda plasament"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							)}
    					/>
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							key={"6_"+this.state.key_date_introducere}
							options={this.state.lista_familii_adoptive}
      						getOptionLabel={option => option.nume}
							noOptionsText="Nu exista familii"
							onChange={(ev,value)=>{this.updateInputValue_autocomplete(ev,value,"id_familie_adoptiva")}}
							disableClearable
							disableListWrap
							autoComplete
							size="small"
							filterOptions={this.filter_options}
							name="id_familie_adoptiva"
							renderInput={params => (
								<TextField
									{...params}
									label="Familie adoptiva"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							)}
    					/>
					</Grid>
					<Grid item xs={2}>
						<Autocomplete
							key={"7_"+this.state.key_date_introducere}
							options={this.state.lista_asistenti_sociali}
      						getOptionLabel={option => option.nume}
							noOptionsText="Nu exista persoane"
							onChange={(ev,value)=>{this.updateInputValue_autocomplete(ev,value,"id_asistent_social")}}
							disableClearable
							disableListWrap
							autoComplete
							size="small"
							filterOptions={this.filter_options}
							name="id_asistent_social"
							renderInput={params => (
								<TextField
									{...params}
									label="Asistent social"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							)}
    					/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="decident"
							select
							label="Decident"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.decident}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.lista_decidenti.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="judet"
							label="Judet"
							fullWidth
							autoComplete="judet"
							value={this.state.data.judet}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="nr_act"
							label="Numar act"
							fullWidth
							autoComplete="nr_act"
							value={this.state.data.nr_act}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="data_act"
							label="Data act"
							fullWidth
							autoComplete="data_act"
							value={this.state.data.data_act}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="activa"
							select
							label="Activa"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.activa}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.boolean_list.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							margin="dense"
							name="motiv"
							select
							label="Motiv"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data.motiv}
							onChange={evt => this.updateInputValue(evt)}
						>
							<option/>
							{this.state.lista_tip_masuri.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="data_inactivarii"
							label="Data inactiv."
							fullWidth
							value={this.state.data.data_inactivarii}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							variant="outlined"
							margin="dense"
							name="observatii_inactivare"
							label="Observatii"
							fullWidth
							value={this.state.data.observatii_inactivare}
							onChange={evt => this.updateInputValue(evt)}
						/>
					</Grid>
					<Grid item={8}>
						{this.forma_operatiuni_butoane()}				
					</Grid>
				</Grid>
			</div>
		);
	}

	forma_operatiuni_butoane() {
		if(this.state.selected_el) {
			return(		
				<div style={{"textAlign":"center","paddingLeft":"90px"}}>
					{this.props.sess.rol>10&&
						<React.Fragment>
							<Button onClick={(evt)=>{this.salveaza_date("modificare")}} color="primary" variant="contained" style={{"marginTop":"9px"}}>
								<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
								Salveaza modificarile
							</Button>
						</React.Fragment>
					}
					<Button onClick={(evt)=>{this.reseteaza("fields")}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","float":"right","marginLeft":"120px"}}>
						<CloseIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Inchide fereastra
					</Button>
				</div>
			);
		}else{
			return(
				<div style={{"textAlign":"center"}}>
					{this.props.sess.rol>10&&
					<	Button onClick={(ev)=>{this.salveaza_date("adaugare")}} color="primary" variant="contained" style={{"marginTop":"9px"}}>
							<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
							Adauga masura noua
						</Button>
					}
					<Button onClick={(evt)=>{this.reseteaza("fields")}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"9px","float":"right","marginLeft":"120px"}}>
						<CloseIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
						Inchide fereastra
					</Button>
				</div>
			);
		}		
	}

	forma_cautare() {
		return(
			<div style={{"padding":"30px"}}>
				<Grid container spacing={1}>
					<Grid item xs={4}>
						<Autocomplete
							key={"7_"+this.state.key_date_cautare}
							options={this.state.lista_copii}
      						getOptionLabel={option => option.nume}
							noOptionsText="Nu exista copii"
							onChange={(ev,value)=>{this.updateInputValueCautare_autocomplete(ev,value,"cautare_id_copil")}}
							disableClearable
							disableListWrap
							autoComplete
							size="small"
							filterOptions={this.filter_options}
							name="cautare_id_copil"
							renderInput={params => (
								<TextField
									{...params}
									label="Copil"
									variant="outlined"
									margin="dense"
									fullWidth
								/>
							)}
    					/>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_masura"
							select
							label="Masura"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_masura}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.lista_tip_masuri.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_tip"
							select
							label="Tip"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_tip}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.lista_tip.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_fel"
							select
							label="Fel"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_fel}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.lista_fel.map((option,index) => (
								<option key={index} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>
					{!!(this.state.data_cautare.cautare_fel==="") &&
						<Grid item xs={3} style={{"textAlign":"center"}}>
							<p style={{"padding":"0px","marginTop":"12px"}}>Selectati felul pentru filtrare.</p>
						</Grid>						
					}
					{!!((this.state.data_cautare.cautare_fel==="FAMILIE NATURALA")||(this.state.data_cautare.cautare_fel==="SOCIETATE")) &&
						<Grid item xs={3} style={{"textAlign":"center"}}>
							&nbsp;
						</Grid>						
					}
					{!!(this.state.data_cautare.cautare_fel==="ASISTENT MATERNAL") &&
						<Grid item xs={3}>
							<Autocomplete
								key={"2_"+this.state.key_date_introducere}
								options={this.state.lista_asistenti_maternali}
								getOptionLabel={option => option.nume}
								noOptionsText="Nu exista persoane"
								onChange={(ev,value)=>{this.updateInputValueCautare_autocomplete(ev,value,"cautare_id_asistent_maternal")}}
								disableClearable
								disableListWrap
								autoComplete
								size="small"
								filterOptions={this.filter_options}
								name="cautare_id_asistent_maternal"
								renderInput={params => (
									<TextField
										{...params}
										label="Asistenti maternali"
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								)}
							/>
						</Grid>						
					}
					{!!(this.state.data_cautare.cautare_fel.indexOf("CENTRU ")>-1) &&
						<Grid item xs={3}>
							<Autocomplete
								key={"2_"+this.state.key_date_introducere}
								options={this.state.lista_centre}
								getOptionLabel={option => option.nume}
								noOptionsText="Nu exista centre"
								onChange={(ev,value)=>{this.updateInputValueCautare_autocomplete(ev,value,"cautare_id_centru")}}
								disableClearable
								disableListWrap
								autoComplete
								size="small"
								filterOptions={this.filter_options}
								name="cautare_id_centru"
								renderInput={params => (
									<TextField
										{...params}
										label="Centru"
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								)}
							/>
						</Grid>						
					}
					{!!(this.state.data_cautare.cautare_fel==="FAMILIE PLASAMENT") &&
						<Grid item xs={3}>
							<Autocomplete
								key={"2_"+this.state.key_date_introducere}
								options={this.state.lista_familii_plasament}
								getOptionLabel={option => option.nume}
								noOptionsText="Nu exista persoane"
								onChange={(ev,value)=>{this.updateInputValueCautare_autocomplete(ev,value,"cautare_id_familie_plasament")}}
								disableClearable
								disableListWrap
								autoComplete
								size="small"
								filterOptions={this.filter_options}
								name="cautare_id_familie_plasament"
								renderInput={params => (
									<TextField
										{...params}
										label="Familii plasament"
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								)}
							/>
						</Grid>						
					}
					{!!(this.state.data_cautare.cautare_fel==="RUDA PLASAMENT") &&
						<Grid item xs={3}>
							<Autocomplete
								key={"2_"+this.state.key_date_introducere}
								options={this.state.lista_rude_plasament}
								getOptionLabel={option => option.nume}
								noOptionsText="Nu exista persoane"
								onChange={(ev,value)=>{this.updateInputValueCautare_autocomplete(ev,value,"cautare_id_ruda_plasament")}}
								disableClearable
								disableListWrap
								autoComplete
								size="small"
								filterOptions={this.filter_options}
								name="cautare_id_ruda_plasament"
								renderInput={params => (
									<TextField
										{...params}
										label="Rude plasament"
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								)}
							/>
						</Grid>						
					}
					{!!(this.state.data_cautare.cautare_fel==="FAMILIE ADOPTIVA") &&
						<Grid item xs={3}>
							<Autocomplete
								key={"2_"+this.state.key_date_introducere}
								options={this.state.lista_familii_adoptive}
								getOptionLabel={option => option.nume}
								noOptionsText="Nu exista persoane"
								onChange={(ev,value)=>{this.updateInputValueCautare_autocomplete(ev,value,"cautare_id_familie_adoptiva")}}
								disableClearable
								disableListWrap
								autoComplete
								size="small"
								filterOptions={this.filter_options}
								name="cautare_id_familie_adoptiva"
								renderInput={params => (
									<TextField
										{...params}
										label="Familii adoptive"
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								)}
							/>
						</Grid>						
					}
					{!!(this.state.data_cautare.cautare_fel==="RUDE GRAD IV") &&
						<Grid item xs={3}>
							<Autocomplete
								key={"2_"+this.state.key_date_introducere}
								options={this.state.lista_rude_plasament}
								getOptionLabel={option => option.nume}
								noOptionsText="Nu exista persoane"
								onChange={(ev,value)=>{this.updateInputValueCautare_autocomplete(ev,value,"cautare_id_ruda_plasament")}}
								disableClearable
								disableListWrap
								autoComplete
								size="small"
								filterOptions={this.filter_options}
								name="cautare_id_ruda_plasament"
								renderInput={params => (
									<TextField
										{...params}
										label="Rude plasament"
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								)}
							/>
						</Grid>						
					}
					<Grid item xs={2}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_localitate"
							label="Localitate locatie"
							fullWidth
							value={this.state.data_cautare.cautare_localitate}
							onChange={evt => this.updateInputValueCautare(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_varsta_min"
							label="Varsta min"
							fullWidth
							value={this.state.data_cautare.cautare_varsta_min}
							onChange={evt => this.updateInputValueCautare(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_varsta_max"
							label="Varsta max"
							fullWidth
							value={this.state.data_cautare.cautare_varsta_max}
							onChange={evt => this.updateInputValueCautare(evt)}
						/>
					</Grid>
					<Grid item xs={1}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
        					<DatePicker
								name="cautare_data_min"  
								clearable
								variant="outlined"
								margin="dense"
								format="dd-MM-yyyy" 
								label="Data min"
								value={this.state.data_cautare.cautare_data_min}
          						onChange={date => this.updateInputValueCautare_data(date,"cautare_data_min")}
        					/>
    					</MuiPickersUtilsProvider>
					</Grid>										
					<Grid item xs={1}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
        					<DatePicker
								name="cautare_data_max"  
								clearable
								variant="outlined"
								margin="dense"
								format="dd-MM-yyyy" 
								label="Data max"
								value={this.state.data_cautare.cautare_data_max}
          						onChange={date => this.updateInputValueCautare_data(date,"cautare_data_max")}
        					/>
    					</MuiPickersUtilsProvider>
					</Grid>								
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_handicap"
							select
							label="Handicap"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_handicap}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.boolean_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>		
					<Grid item xs={1}>
						<TextField
							variant="outlined"
							margin="dense"
							name="cautare_istoric"
							select
							label="Istoric"
							fullWidth
							SelectProps={{
								native: true,
							}}
							value={this.state.data_cautare.cautare_istoric}
							onChange={evt => this.updateInputValueCautare(evt)}
						>
							<option/>
							{this.state.boolean_list.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</TextField>
					</Grid>		
					<Grid item xs={4}>
						{this.forma_cautare_butoane()}
					</Grid>					
				</Grid>
			</div>
		);
	}

	forma_cautare_butoane() {
		return(
			<div style={{"width":"100%","textAlign":"center"}}>
				<Button onClick={(evt)=>{this.cautare_masura()}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px","marginRight":"12px"}}>
					<SearchIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
					Cautare
				</Button>
				<Button onClick={(evt)=>{this.reseteaza_date_cautare("all")}} color="primary" variant="contained" style={{"marginTop":"9px","width":"150px"}}>
					<RefreshIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
					Reseteaza
				</Button>
				<Button onClick={(evt)=>{this.setState({anchorEl_filtrare: null})}} color="primary" variant="contained" style={{"marginTop":"9px","marginRight":"12px","float":"right"}}>
					<CloseIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
					Inchide
				</Button>
		</div>
		);
	}

	render_operatiuni_menu() {
		return (
			<Menu
				anchorEl={this.state.anchorEl_operatiuni}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				keepMounted={true}
				open={Boolean(this.state.anchorEl_operatiuni)}
				onClose={(ev)=>this.setState({anchorEl_operatiuni: null})}
			>
				{this.forma_operatiuni()}
			</Menu>
		);
	};

	render_filtrare_menu() {
		return (
			<Menu
				anchorEl={this.state.anchorEl_filtrare}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				keepMounted={true}
				open={Boolean(this.state.anchorEl_filtrare)}
				onClose={(ev)=>this.setState({anchorEl_filtrare: null})}
			>
				{this.forma_cautare()}
			</Menu>
		);
	};

	render() {
		if(this.state.is_loaded===1) {
			let titlu_rezultate="Rezultate cautare ("+this.state.lista_masuri.length+" rezultate)";
			if(this.ok_cautare>0) {
				titlu_rezultate+=" ("+this.ok_cautare+" filtre aplicate)";
			}
			return(
				<div className="fix_height">
					<Fieldset legend={titlu_rezultate} name="tabel_items" id="tabel_items" className="text_center" style={{"padding":"0px","margin":"0px","flex":"1 1 auto"}}>
						<Grid container spacing={1} style={{"marginBottom":"3px","marginTop":"-12px"}}>
							{!!(this.ok_cautare>0) &&
								<Grid item xs={2}>
									<Badge badgeContent={this.ok_cautare} color="secondary">
										<Button onClick={(evt)=>{this.setState({anchorEl_filtrare: evt.currentTarget})}} color="primary" variant="contained" fullWidth>
											<SearchIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Filtreaza
										</Button>
									</Badge>								
								</Grid>
							}
							{!!(this.ok_cautare===0) &&
								<Grid item xs={2}>
									<Button onClick={(evt)=>{this.setState({anchorEl_filtrare: evt.currentTarget})}} color="primary" variant="contained" fullWidth>
										<SearchIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
										Filtreaza
									</Button>
								</Grid>
							}
							{!!(this.state.export_excel===1) &&
								<React.Fragment>
									<Grid item xs={2}>
										<RaportExcel nume={"masuri"} data={this.state.lista_masuri}/>
									</Grid>
									<Grid item xs={2}>
										&nbsp;
									</Grid>
								</React.Fragment>
							}
							{!!(!(this.state.export_excel===1)) &&
								<Grid item xs={4}>
									&nbsp;
								</Grid>
							}							
							{!!(!(this.state.selected_el)) &&
								<React.Fragment>
									<Grid item xs={2}>
										&nbsp;
									</Grid>
									<Grid item xs={2}>
										&nbsp;
									</Grid>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.setState({anchorEl_operatiuni: evt.currentTarget,selected_el: null,key_date_introducere: Math.random()})}} color="primary" variant="contained" fullWidth>
											<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Adauga masura
										</Button>
									</Grid>
								</React.Fragment>
							}
							{!!(this.state.selected_el) &&
								<React.Fragment>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.setState({anchorEl_operatiuni: evt.currentTarget})}} color="primary" variant="contained" fullWidth>
											<EditIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Modifica masura
										</Button>
									</Grid>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.sterge_date()}} color="secondary" variant="contained" fullWidth>
											<DeleteOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Sterge masura
										</Button>
									</Grid>
									<Grid item xs={2}>
										<Button onClick={(evt)=>{this.reseteaza_date_cautare()}} color="primary" variant="contained" fullWidth>
											<AddCircleOutlineIcon className="icon_button" style={{"marginLeft":"1px","marginRight":"5px"}}/>
											Adauga masura
										</Button>
									</Grid>
								</React.Fragment>
							}
						</Grid>
						<DataTable ref={(el) => this.dt = el} 
							value={this.state.lista_masuri}
							scrollable={true}
							paginator={true}
							rows={15}
							selectionMode="single"
							selection={this.state.selected_el}
							onSelectionChange={(e)=>{this.selectare(e)}}
							sortField="nume_prenume"
							sortOrder={1}
						>
							<Column field="cod_intern" header="Cod intern" style={{"width":"12%"}} filter={true} filterMatchMode="contains"/>
							<Column field="nume_copil" header="Nume si prenume copil" style={{"width":"18%"}} filter={true} filterMatchMode="contains"/>
							<Column field="fel" header="Fel" style={{"width":"14%"}} filter={true} filterMatchMode="contains"/>
							<Column field="locatie_copil" header="Locatie copil" style={{"width":"30%"}} filter={true} filterMatchMode="contains"/>
							<Column field="varsta_ani_luni" header="Varsta" style={{"width":"5%"}} filter={true} filterMatchMode="contains"/>
							<Column field="handicap_nume" header="Hand." style={{"width":"6%"}} filter={true} filterMatchMode="contains"/>
							<Column field="data_act" header="Data act" style={{"width":"9%"}}/>							
							<Column field="nume_activa" header="Activa" style={{"width":"5%"}} filter={true} filterMatchMode="contains"/>
						</DataTable>
					</Fieldset>
					{this.render_filtrare_menu()}
					{this.render_operatiuni_menu()}
					{this.message()}
				</div>
			)			
		}else{
			return(
				<LinearProgress/>
			);
		}
	}
}

export default Masuri;
