import React from 'react';
import PropTypes from 'prop-types';
import {fetch_url_post} from "../Sources/js/app.js";
import Utilizatori from './Sectiuni/Utilizatori';
import Centre from './Sectiuni/Centre';
import AsistentiMaternali from './Sectiuni/AsistentiMaternali';
import Copii from './Sectiuni/Copii';
import AsistentiSociali from './Sectiuni/AsistentiSociali';
import FamiliiPlasament from './Sectiuni/FamiliiPlasament';
import FamiliiAdoptive from './Sectiuni/FamiliiAdoptive';
import Masuri from './Sectiuni/Masuri';
import FamiliiRecuperare from './Sectiuni/FamiliiRecuperare';
import RaportRecuperare from './Sectiuni/RaportRecuperare';

class MainPage extends React.Component {
    constructor(props) {
		super(props);
		this.state={
			is_loaded: 1,
			open_serviciu: false,
			serviciu_curent: "",
			setari: [],
		};
		this.success_handler=this.success_handler.bind(this);
		this.fail_handler=this.fail_handler.bind(this);
		this.logout_func=this.logout_func.bind(this);
		this.sectiune_content=this.sectiune_content.bind(this);
	}

    success_handler(result) {
		this.setState({
			servicii_categorii: result.data.servicii_categorii,
			servicii: result.data.servicii,
			setari: result.data.setari,
			is_loaded: 1
		});
    }

    fail_handler() {
    	this.setState({
			servicii:[],
			setari:[],
			is_loaded: 1
		});
    }

    logout() {
		var url="logout.asp?session-id="+this.props.sess.sessid;
		fetch_url_post(url,this.state,this.success_handler_logout,this.fail_handler_logout);
    }

    componentDidMount() {

	}

	start_serviciu(ev,serviciu_curent) {
		this.setState({open_serviciu: true,serviciu_curent: serviciu_curent});
	}

	hide_serviciu() {
		this.setState({open_serviciu: false});
	}

    find_element(where,what) {
        return where.indexOf(what);
	}
	
	logout_func() {
		this.props.logout();
	}

	sectiune_content() {
		if(this.props.serviciu_curent==="utilizatori") {
			return(
				<Utilizatori sess={this.props.sess}/>
			);
		}
		if(this.props.serviciu_curent==="centre") {
			return(
				<Centre sess={this.props.sess}/>				
			);
		}
		if(this.props.serviciu_curent==="asistenti_maternali") {
			return(
				<AsistentiMaternali sess={this.props.sess}/>				
			);
		}
		if(this.props.serviciu_curent==="copii") {
			return(
				<Copii sess={this.props.sess}/>
			);
		}
		if(this.props.serviciu_curent==="asistenti_sociali") {
			return(
				<AsistentiSociali sess={this.props.sess}/>
			);
		}
		if(this.props.serviciu_curent==="masuri") {
			return(
				<Masuri sess={this.props.sess}/>
			);
		}
		if(this.props.serviciu_curent==="familii_plasament") {
			return(
				<FamiliiPlasament sess={this.props.sess}/>
			);
		}
		if(this.props.serviciu_curent==="familii_adoptive") {
			return(
				<FamiliiAdoptive sess={this.props.sess}/>
			);
		}
		if(this.props.serviciu_curent==="familii_recuperare") {
			return(
				<FamiliiRecuperare sess={this.props.sess}/>
			);
		}
		if(this.props.serviciu_curent==="raport_recuperare") {
			return(
				<RaportRecuperare sess={this.props.sess}/>
			);
		}
	}

	render() {
		if(this.state.is_loaded===0) {
			return(<div>Loading ... </div>);
		}else{
			return(
				<div>
					{this.sectiune_content()}
				</div>
			);
		}
	}
}

MainPage.propTypes = {
	onClick: PropTypes.func
};

MainPage.defaultProps = {

};

export default MainPage;